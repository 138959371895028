import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Add from '../../../assets/svg/Add'
import OutlinedFilter from '../../../assets/svg/OutlinedFilter'
import AsyncSelect from '../../../components/AsyncSelect'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import RadioButton from '../../../components/RadioButton'
import SearchInput from '../../../components/SearchInput'
import { getCodebook, getSingleClub, getTournaments } from '../../../lib/api'
import FinishedTournaments from './Finished'
import UpcomingTournamets from './Upcoming'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import { useAppContext } from '../../../lib/contextLib'
import {
    debounce,
    formatDate,
    getClubList,
    handleGenders,
} from '../../../lib/helpers'
import { font } from '../../Admin/Competitions/CompetitionProfile/font'
import './styles.scss'

const searchInputID = `searchInput-competitions`

const AllTournaments = ({ setIsCreatingClub }) => {
    const { user } = useAppContext()
    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    //* Codebooks
    const [regions, setRegions] = useState([])
    const [tournamentCategories, setTournamentCategories] = useState([])
    const [tournamentSubCategories, setTournamentSubCategories] = useState([])
    const [tournamentOrganisationTypes, setTournamentOrganisationTypes] =
        useState([])
    const [genders, setGenders] = useState([])
    const [playersCompetition, setPlayersCompetition] = useState([])
    const competitorCompetitions = [
        '10s - Crveni',
        '10s - Narandžasti',
        '10s - Zeleni',
    ]

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
        await getCodebook('codebooks.region', setRegions)
        await getCodebook(
            'codebooks.tournament-organisation-type',
            setTournamentOrganisationTypes
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentCategories
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentSubCategories,
            true
        )
        await getCodebook('codebooks.players-competition').then((result) => {
            setPlayersCompetition(
                result.reduce((accumulator, currentValue) => {
                    if (!['10', '45'].includes(currentValue)) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
    }

    // Filtering
    const [showFilters, setShowFilters] = useState(false)

    const initialFilters = {
        player_birthdate:
            user?.data?.attributes?.person_info?.birthdate ?? '1970-01-01',
        tournament_type: 'Individualno',
        genderList: user?.data?.attributes?.gender?.gender_name ?? null,
        region: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
    }

    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const [filterParams, setFilterParams] = useState(initialFilters)
    const [selectedClub, setSelectedClub] = useState(null)

    // Club selector in MultiSelectSearch
    const handleClubSelect = (e) => {
        const { uuid, value } = e

        setSelectedClub({ uuid: uuid, value: value })
        setFilterParams({ ...filterParams, club_organizer_uuid: uuid })
    }

    const getComingCompetitions = async (page, presetFilters) => {
        let search = document.getElementById(searchInputID)

        if (search) {
            let searchParams = { name: search.value }

            let { tournaments, pgCount, total } = await getTournaments(
                null,
                searchParams,
                presetFilters
                    ? presetFilters === 1
                        ? null
                        : presetFilters
                    : filterParams,
                page,
                false,
                false,
                rowsPerPage
            )

            if (tournaments) {
                const tournamentsPromises = tournaments.map(
                    async (tournament) => {
                        const club_organizer = await getSingleClub(
                            tournament.attributes.club_organizer_uuid
                        )
                        return {
                            ...tournament,
                            attributes: {
                                ...tournament.attributes,
                                club_organizer,
                            },
                        }
                    }
                )

                const updatedTournaments = await Promise.all(
                    tournamentsPromises
                )

                setComingCompetitions(updatedTournaments)
                setPageCount(pgCount)
                setTotal(total)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
    }

    const getAllFinishedCompetitions = async (page, presetFilters) => {
        let search = document.getElementById(searchInputID)

        if (search) {
            let searchParams = { name: search.value }

            let { tournaments, pgCount, total } = await getTournaments(
                null,
                searchParams,
                presetFilters
                    ? presetFilters === 1
                        ? null
                        : presetFilters
                    : filterParams,
                page,
                true,
                false,
                rowsPerPage
            )

            if (tournaments) {
                const tournamentsPromises = tournaments.map(
                    async (tournament) => {
                        const club_organizer = await getSingleClub(
                            tournament.attributes.club_organizer_uuid
                        )
                        return {
                            ...tournament,
                            attributes: {
                                ...tournament.attributes,
                                club_organizer,
                            },
                        }
                    }
                )

                const updatedTournaments = await Promise.all(
                    tournamentsPromises
                )

                setFinishedCompetitions(updatedTournaments)
                setPageCount(pgCount)
                setTotal(total)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage)
            : getAllFinishedCompetitions(currentPage)
        handleCodebooks()
    }, [currentPage, selectedTab, rowsPerPage])

    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        setTotal(1)
        selectedTab === 'nadolazeca'
            ? await getComingCompetitions(1)
            : await getAllFinishedCompetitions(1)
    }, 1000)

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        setSelectedClub(null)
        setShowFilters(false)
        document.getElementById(searchInputID).value = ''
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(1, initialFilters)
            : getAllFinishedCompetitions(1, initialFilters)
    }

    const handleTabState = (selectedTab) => {
        switch (selectedTab) {
            case 'nadolazeca':
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            case 'zavrsena':
                return (
                    <FinishedTournaments
                        tournaments={finishedCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            default:
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
        }
    }

    const handlePdfExport = () => {
        if (selectedTab === 'nadolazeca') {
            handlePdfExportComing()
        } else if (selectedTab === 'zavrsena') {
            handlePdfExportFinished()
        }
    }

    const countHelper = (separator = '-') => {
        return `${(currentPage - 1) * rowsPerPage + 1}${separator}${
            rowsPerPage * currentPage
        }`
    }

    // Print PDF coming and finished competitions

    const handlePdfExportComing = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Nadolazeća takmičenja - ${formatDate(
            new Date()
        )} - ${countHelper()} - ${new Date().toLocaleTimeString()}`
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= comingCompetitions?.length; i++) {
            const compt = comingCompetitions[i - 1]
            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : 'Nema informacije',

                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : 'Nema informacije',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.genders
                    ? handleGenders(compt?.attributes?.genders)
                    : 'Nema informacije',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : 'Nema informacije',
            ])
        }

        let comingCompetitionsContent = {
            startY: 50,
            head: [
                [
                    'Naziv',
                    'Organizator',
                    'Prijava do',
                    'Odjava do',
                    'Pol',
                    'Kategorija',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(comingCompetitionsContent)
        doc.save(
            `Nadolazeća_takmičenja_${formatDate(new Date()).slice(
                0,
                -1
            )}_${countHelper('_')}.pdf`
        )
        setShowFilters(false)
    }

    const handlePdfExportFinished = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Završena takmičenja - ${formatDate(
            new Date()
        )} - ${countHelper()} - ${new Date().toLocaleTimeString()}`
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= finishedCompetitions?.length; i++) {
            const compt = finishedCompetitions[i - 1]
            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : 'Nema informacije',

                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : 'Nema informacije',
                compt?.attributes?.genders
                    ? handleGenders(compt?.attributes?.genders)
                    : 'Nema informacije',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : 'Nema informacije',
            ])
        }
        let finishedCompetitionsContent = {
            startY: 50,
            head: [['Naziv', 'Organizator', 'Prijava do', 'Pol', 'Kategorija']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(finishedCompetitionsContent)
        doc.save(
            `Završena_takmičenja_${formatDate(new Date()).slice(
                0,
                -1
            )}_${countHelper('_')}.pdf`
        )
        setShowFilters(false)
    }

    const handleTabSelect = (tab) => {
        setSelectedTab((_) => {
            tab === 'nadolazeca'
                ? getComingCompetitions(1)
                : getAllFinishedCompetitions(1)
            return tab
        })
    }

    return (
        <Row className="tournaments-wrapper position-relative">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    Takmičenja
                                </h2>
                                <p className="d-block d-md-none">Takmičenja</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/takmicar/pocetna' }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Takmičenja
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center d-md-none">
                            <Button
                                type="text"
                                onClick={() => setShowFilters(true)}
                            >
                                <OutlinedFilter />
                            </Button>
                        </div>
                    </Col>
                    <Offcanvas
                        show={showFilters}
                        onHide={() => setShowFilters(false)}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <h3>Filtriraj</h3>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <hr className="p-0 mt-2 mb-2" />
                        <Offcanvas.Body>
                            <Row>
                                <Col md={12} className="mt-3">
                                    <RadioButton
                                        name="Pol"
                                        radioValues={genders}
                                        onClick={(e) => {
                                            filterParams?.gender ===
                                            e.target.value
                                                ? setFilterParams({
                                                      ...filterParams,
                                                      gender: '',
                                                  })
                                                : null
                                        }}
                                        onChange={(e) => {
                                            setFilterParams({
                                                ...filterParams,
                                                gender: e.target.value,
                                            })
                                        }}
                                        checkedValue={
                                            filterParams?.gender || ''
                                        }
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <AsyncSelect
                                        loadOptions={getClubList}
                                        onChange={handleClubSelect}
                                        label={'Izaberi klub'}
                                        value={selectedClub}
                                        placeholder={
                                            'Pretraži i izaberi klub...'
                                        }
                                        noOptionsMessage={
                                            'Traženi klub ne postoji'
                                        }
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Dropdown
                                        label={'Region'}
                                        value={filterParams?.region || ''}
                                        placeholder="Region"
                                        handleSelect={(e) =>
                                            setFilterParams({
                                                ...filterParams,
                                                region:
                                                    e === 'Odselektuj' ? '' : e,
                                            })
                                        }
                                        dropdownLinks={
                                            regions
                                                ? regions
                                                      .filter(
                                                          (region) =>
                                                              region !== 'TSS'
                                                      )
                                                      .reduce(
                                                          (
                                                              accumulator,
                                                              region
                                                          ) => [
                                                              ...accumulator,
                                                              region,
                                                          ],
                                                          filterParams?.region
                                                              ? ['Odselektuj']
                                                              : []
                                                      )
                                                : []
                                        }
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Dropdown
                                        label={'Konkurencija'}
                                        className="yearDropdown"
                                        value={filterParams?.competition || ''}
                                        placeholder="Konkurencija"
                                        handleSelect={(e) => {
                                            setFilterParams({
                                                ...filterParams,
                                                competition:
                                                    e === 'Odselektuj' ? '' : e,
                                            })
                                        }}
                                        dropdownLinks={playersCompetition?.reduce(
                                            (accumulator, currentValue) => [
                                                ...accumulator,
                                                currentValue,
                                            ],
                                            filterParams?.competition
                                                ? [
                                                      'Odselektuj',
                                                      ...competitorCompetitions,
                                                  ]
                                                : competitorCompetitions
                                        )}
                                    />
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Dropdown
                                        label={'Kategorija'}
                                        className="category-dropdown"
                                        value={filterParams?.category || ''}
                                        placeholder="Kategorija"
                                        handleSelect={(e) =>
                                            setFilterParams({
                                                ...filterParams,
                                                category:
                                                    e === 'Odselektuj' ? '' : e,
                                            })
                                        }
                                        dropdownLinks={tournamentCategories?.reduce(
                                            (accumulator, category) => [
                                                ...accumulator,
                                                category,
                                            ],
                                            filterParams?.category
                                                ? ['Odselektuj']
                                                : []
                                        )}
                                    />
                                </Col>

                                {filterParams?.category === 'III' && (
                                    <Col md={12} className="mt-3">
                                        <Dropdown
                                            label={'Potkategorija'}
                                            value={
                                                filterParams?.sub_category || ''
                                            }
                                            placeholder="Potkategorija"
                                            handleSelect={(e) =>
                                                setFilterParams({
                                                    ...filterParams,
                                                    sub_category:
                                                        e === 'Odselektuj'
                                                            ? ''
                                                            : e,
                                                })
                                            }
                                            dropdownLinks={tournamentSubCategories?.reduce(
                                                (accumulator, subcategory) => [
                                                    ...accumulator,
                                                    subcategory,
                                                ],
                                                filterParams?.sub_category
                                                    ? ['Odselektuj']
                                                    : []
                                            )}
                                        />
                                    </Col>
                                )}

                                <Col md={12} className="mt-3">
                                    <Dropdown
                                        label={'Tip organizovanja takmičenja'}
                                        placeholder="Izaberi tip organizovanja..."
                                        value={
                                            filterParams?.tournament_organisation_type ||
                                            ''
                                        }
                                        handleSelect={(e) =>
                                            setFilterParams({
                                                ...filterParams,
                                                tournament_organisation_type:
                                                    e === 'Odselektuj' ? '' : e,
                                            })
                                        }
                                        dropdownLinks={tournamentOrganisationTypes?.reduce(
                                            (accumulator, category) => [
                                                ...accumulator,
                                                category,
                                            ],
                                            filterParams?.tournament_organisation_type
                                                ? ['Odselektuj']
                                                : []
                                        )}
                                    />
                                </Col>

                                <Col md={12} className="mt-3">
                                    <Button
                                        className="w-100"
                                        onClick={async () => {
                                            selectedTab === 'nadolazeca'
                                                ? await getComingCompetitions(1)
                                                : await getAllFinishedCompetitions(
                                                      1
                                                  )
                                        }}
                                    >
                                        Prikaži rezultate
                                    </Button>
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Button
                                        type="secondary"
                                        className="w-100"
                                        onClick={handlePdfExport}
                                    >
                                        Štampaj rezultate
                                    </Button>
                                    <Button
                                        type="text"
                                        className="w-100 mt-3"
                                        onClick={() => handleDisableFiltering()}
                                    >
                                        Ukloni filtriranje
                                    </Button>
                                </Col>
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Col xs={12} md={7} className="offset-md-1">
                        <Row className="d-flex align-items-center">
                            <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                <SearchInput
                                    id={searchInputID}
                                    placeholder="Pronađi takmičenje po imenu"
                                    onChange={(e) =>
                                        updateDebounceText(e.target.value)
                                    }
                                />
                                <Button
                                    type="secondary"
                                    className="mx-2 d-none d-md-block"
                                    onClick={() => setShowFilters(true)}
                                >
                                    Filtriraj
                                </Button>
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Col className="bg-white">
                            <Tabs
                                id="competition-selection"
                                defaultActiveKey={selectedTab}
                                activeKey={selectedTab}
                                onSelect={(tab) => {
                                    setCurrentPage(1)
                                    setPageCount(1)
                                    document.getElementById(
                                        searchInputID
                                    ).value = ''
                                    handleDisableFiltering()
                                    handleTabSelect(tab)
                                }}
                            >
                                <Tab
                                    key="nadolazeca"
                                    eventKey="nadolazeca"
                                    title={<p>Nadolazeća</p>}
                                    tabClassName="d-flex"
                                ></Tab>
                                <Tab
                                    key="zavrsena"
                                    eventKey="zavrsena"
                                    title={<p>Završena</p>}
                                    tabClassName="d-flex"
                                ></Tab>
                            </Tabs>
                        </Col>
                        {handleTabState(selectedTab)}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default AllTournaments
