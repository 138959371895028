import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { updateClubAssemblyRankingPoints } from '../../../lib/api'
import { formatImgUrl, notLeadingZero, numbersOnly } from '../../../lib/helpers'
import { useDebounce } from '../../../lib/hooks/useDebounce'
import { generateAvatar } from '../../../utils/generateAvatar'
import TextField from '../../TextField'
import '../ClubCard/styles.scss'
import { useAppContext } from '../../../lib/contextLib'

const ClubAssemblyCard = ({ isWhite, club, setRefreshData }) => {
    const { user } = useAppContext()
    const [mounted, setMounted] = useState(false)
    const [inputValue, setInputValue] = useState()
    const [inputValueOrganization, setInputValueOrganization] = useState()
    const debouncedInputValue = useDebounce(inputValue)
    const debouncedInputValueOrganization = useDebounce(inputValueOrganization)

    const handleInputChange = async (e) => {
        const { id, value } = e.target

        const response = await updateClubAssemblyRankingPoints(
            club?.attributes?.uuid,
            {
                [id]: +value,
            }
        )

        let zeroValue = !value ? 0 : value

        let inputValueChanged = zeroValue !== club?.attributes?.[id]

        if (response) {
            setInputValue(null)
            setInputValueOrganization(null)
            let activeElement = document.activeElement
            inputValueChanged && activeElement.blur()
            setRefreshData(true)
        }
    }

    const handlerOnBlur = (e) => {
        if (e.target.value === '') {
            e.target.value = 0
        }
    }

    useEffect(() => {
        if (mounted) {
            if (debouncedInputValue === null) {
                return
            }
            handleInputChange(debouncedInputValue)
        } else {
            setMounted(true)
        }
    }, [debouncedInputValue])

    useEffect(() => {
        if (mounted) {
            if (debouncedInputValueOrganization === null) {
                return
            }
            handleInputChange(debouncedInputValueOrganization)
        } else {
            setMounted(true)
        }
    }, [debouncedInputValueOrganization])

    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Klub</h5>
                            </Col>
                            <Col xs={7} className="d-flex align-items-center">
                                <img
                                    src={
                                        club?.club_data?.attributes?.logo?.data
                                            ? formatImgUrl(
                                                  club?.club_data?.attributes
                                                      ?.logo?.data?.attributes
                                                      ?.formats?.thumbnail?.url
                                              )
                                            : generateAvatar(
                                                  club?.club_data?.attributes
                                                      ?.name
                                              )
                                    }
                                    className="me-2"
                                    alt={`Logo kluba`}
                                    width={'24px'}
                                    height={'24px'}
                                />
                                <Link
                                    to={`/klubovi/${club?.club_data?.attributes?.uuid}`}
                                >
                                    <p className="link">
                                        {club?.club_data?.attributes?.name}
                                    </p>
                                </Link>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center justify-content-center">
                            <Col
                                xs={12}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <h4>Broj glasova</h4>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Registrovani igrači</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {club?.attributes
                                        ?.registered_player_votes || 0}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Rangirani igrači</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {club?.attributes?.ranked_player_votes || 0}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Tereni</h5>
                            </Col>
                            <Col xs={7}>
                                <p>{club?.attributes?.court_votes || 0}</p>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Kvalitet klubova</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {club?.attributes?.club_quality_votes || 0}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Organizacija međunarodnih turnira</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {club?.attributes
                                        ?.international_organization_votes || 0}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>
                                    Dodatni glasovi za organizaciju međ. turnira
                                </h5>
                            </Col>
                            <Col xs={7}>
                                {user?.role?.type !== 'competitor' ? (
                                    <TextField
                                        className="table-points-input-field"
                                        id="extra_international_organization_votes"
                                        name="extra_international_organization_votes"
                                        type="tel"
                                        onChange={(e) => {
                                            numbersOnly(e)
                                            notLeadingZero(e)
                                            setInputValueOrganization(e)
                                        }}
                                        onBlur={handlerOnBlur}
                                        value={
                                            inputValueOrganization
                                                ? inputValueOrganization?.target
                                                      ?.value
                                                : club?.attributes
                                                      ?.extra_international_organization_votes
                                                ? club?.attributes
                                                      ?.extra_international_organization_votes
                                                : 0
                                        }
                                    />
                                ) : (
                                    <p>
                                        {inputValueOrganization
                                            ? inputValueOrganization?.target
                                                  ?.value
                                            : club?.attributes
                                                  ?.extra_international_organization_votes
                                            ? club?.attributes
                                                  ?.extra_international_organization_votes
                                            : 0}
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Internacionalni rang</h5>
                            </Col>
                            <Col xs={7}>
                                {user?.role?.type !== 'competitor' ? (
                                    <TextField
                                        className="table-points-input-field"
                                        id="international_rank_votes"
                                        name="international_rank_votes"
                                        type="tel"
                                        onChange={(e) => {
                                            numbersOnly(e)
                                            notLeadingZero(e)
                                            setInputValue(e)
                                        }}
                                        onBlur={handlerOnBlur}
                                        value={
                                            inputValue
                                                ? inputValue?.target?.value
                                                : club?.attributes
                                                      ?.international_rank_votes
                                                ? club?.attributes
                                                      ?.international_rank_votes
                                                : 0
                                        }
                                    />
                                ) : (
                                    <p>
                                        {inputValue
                                            ? inputValue?.target?.value
                                            : club?.attributes
                                                  ?.international_rank_votes
                                            ? club?.attributes
                                                  ?.international_rank_votes
                                            : 0}
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Ukupno</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {club?.attributes?.total_votes
                                        ? club?.attributes?.total_votes > 15
                                            ? 15
                                            : club?.attributes?.total_votes
                                        : 0}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ClubAssemblyCard
