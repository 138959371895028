import React, { useState } from 'react'

import { Fragment } from 'react'
import Button from '../../../../../../components/Button'
import ConfirmationModal from '../../../../../../components/ConfirmationModal'
import { deleteScheduleDate } from '../../../../../../lib/api'

const RemoveScheduleDateModal = ({
    scheduleDate,
    handleTournamentDates,
    tournamentScheduleDates,
}) => {
    const [show, setShow] = useState(false)

    const parseAndFind = (arr, originalDateStr) => {
        const datePart = originalDateStr.split(',')[1].trim()

        const cleanDate = datePart.replace(/\.$/, '').trim()

        const [dayStr, monthStr, yearStr] = cleanDate
            .split('.')
            .map((s) => s.trim())

        const day = dayStr.padStart(2, '0')
        const month = monthStr.padStart(2, '0')
        const year = yearStr

        const targetDate = `${year}-${month}-${day}`

        return arr.find((item) => item.attributes.schedule_date === targetDate)
    }

    const selectedDate = parseAndFind(tournamentScheduleDates, scheduleDate)

    const handleDeleteScheduleDate = async () => {
        try {
            const deletedField = await deleteScheduleDate(
                selectedDate?.attributes?.uuid
            )
            if (!deletedField) {
                throw new Error('Error deleting field')
            }
            setShow(false)
            handleTournamentDates()
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Fragment>
            <Button
                type="secondary"
                className="me-md-3"
                onClick={() => setShow(true)}
            >
                Ukloni takmičarski dan
            </Button>

            <ConfirmationModal
                show={show}
                onHide={() => setShow(false)}
                modalTitle="Potvrda brisanja"
                modalText="Da li ste sigurni da želite da obrišete takmičarski dan? Brisanjem takmičarskog dana se brišu i svi mečevi u rasporedu za ovaj takmičarski dan."
                onClick={handleDeleteScheduleDate}
            />
        </Fragment>
    )
}

export default RemoveScheduleDateModal
