import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React, { useEffect, useState } from 'react'
import { Col, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'
import Add from '../../../assets/svg/Add'
import OutlinedFilter from '../../../assets/svg/OutlinedFilter'
import Settings from '../../../assets/svg/Settings'
import AsyncSelect from '../../../components/AsyncSelect'
import Button from '../../../components/Button'
import CompetitionCard from '../../../components/CompetitionsTableCards/CompetitionsCard'
import Dropdown from '../../../components/Dropdown'
import SearchInput from '../../../components/SearchInput'
import TableCompetitionsPagination from '../../../components/TableCompetitonsPagination'
import {
    getCodebook,
    getSingleClubWithQuery,
    getTournamentReport,
    getTournaments,
} from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import { formatDate, getClubList, isArrayEmpty } from '../../../lib/helpers'
import { font } from '../Competitions/CompetitionProfile/font'

import NoDataForView from '../../../components/NoDataForView'
import RadioButton from '../../../components/RadioButton'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import './styles.scss'

const Competitions = () => {
    const { setIsLoading, setSgpDataForPDF } = useAppContext()
    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    //* Codebooks
    const [regions, setRegions] = useState([])
    const [tournamentCategories, setTournamentCategories] = useState([])
    const [tournamentSubCategories, setTournamentSubCategories] = useState([])
    const [tournamentOrganisationTypes, setTournamentOrganisationTypes] =
        useState([])
    const [genders, setGenders] = useState([])
    const [playersCompetition, setPlayersCompetition] = useState([])
    const competitorCompetitions = [
        '10s - Crveni',
        '10s - Narandžasti',
        '10s - Zeleni',
    ]

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
        await getCodebook('codebooks.region', setRegions)
        await getCodebook(
            'codebooks.tournament-organisation-type',
            setTournamentOrganisationTypes
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentCategories
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentSubCategories,
            true
        )
        await getCodebook('codebooks.players-competition').then((result) => {
            setPlayersCompetition(
                result.reduce((accumulator, currentValue) => {
                    if (!['10', '45'].includes(currentValue)) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
    }

    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])
    const [searchValue, setSearchValue] = useState('')

    // Filtering
    const [showFilters, setShowFilters] = useState(false)

    const initialFilters = {
        gender: '',
        region: '',
        competition: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
    }

    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const [filterParams, setFilterParams] = useState(initialFilters)
    const [selectedClub, setSelectedClub] = useState(null)

    // Club selector in MultiSelectSearch
    const handleClubSelect = (e) => {
        const { uuid, value } = e

        setSelectedClub({ uuid: uuid, value: value })
        setFilterParams({ ...filterParams, club_organizer_uuid: uuid })
    }

    const getComingCompetitions = async (
        page,
        presetFilters,
        allEntries = false
    ) => {
        setIsLoading(true)
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getTournaments(
            null,
            {
                name: searchValue,
            },
            presetFilters
                ? presetFilters === 1
                    ? null
                    : presetFilters
                : filterParams,
            page,
            false,
            allEntries,
            rowsPerPage
        )

        if (tournaments) {
            const tournamentsPromises = tournaments.map(async (tournament) => {
                const club_organizer = await getSingleClubWithQuery(
                    tournament.attributes.club_organizer_uuid,
                    'fields[0]=name&fields[1]=short_name&fields[2]=uuid&populate[logo][populate]'
                )
                return {
                    ...tournament,
                    attributes: {
                        ...tournament.attributes,
                        club_organizer,
                    },
                }
            })

            const updatedTournaments = await Promise.all(tournamentsPromises)
            if (allEntries) {
                setIsLoading(false)
                return updatedTournaments
            } else {
                setComingCompetitions(updatedTournaments)
                setPageCount(pgCount)
                setTotal(totalCount)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
        setIsLoading(false)
    }

    const getAllFinishedCompetitions = async (
        page,
        presetFilters,
        allEntries = false
    ) => {
        setIsLoading(true)
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getTournaments(
            null,
            {
                name: searchValue,
            },
            presetFilters
                ? presetFilters === 1
                    ? null
                    : presetFilters
                : filterParams,
            page,
            true,
            allEntries,
            rowsPerPage
        )

        if (tournaments) {
            const tournamentsPromises = tournaments.map(async (tournament) => {
                const club_organizer = await getSingleClubWithQuery(
                    tournament.attributes.club_organizer_uuid,
                    'fields[0]=name&fields[1]=short_name&fields[2]=uuid&populate[logo][populate]'
                )
                return {
                    ...tournament,
                    attributes: {
                        ...tournament.attributes,
                        club_organizer,
                    },
                }
            })

            const updatedTournaments = await Promise.all(tournamentsPromises)

            if (allEntries) {
                setIsLoading(false)
                return updatedTournaments
            } else {
                setFinishedCompetitions(updatedTournaments)
                setPageCount(pgCount)
                setTotal(totalCount)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage)
            : getAllFinishedCompetitions(currentPage)
        handleCodebooks()
        setSgpDataForPDF({
            groupMatches: [],
            drawToShow: null,
            tournament: null,
        })
    }, [currentPage, selectedTab, searchValue, rowsPerPage])

    useEffect(() => {
        localStorage.removeItem('competitionDrawToShow')
    }, [])

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        setSelectedClub(null)
        setShowFilters(false)
        setSearchValue('')
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(1, 1)
            : getAllFinishedCompetitions(1, 1)
    }

    // Print PDF coming and finished competitions

    const handlePdfExportComing = async () => {
        const filteredTournaments = await getComingCompetitions(
            1,
            filterParams,
            true
        )
        setIsLoading(true)
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = 'Nadolazeća takmičenja'
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredTournaments?.length; i++) {
            const compt = filteredTournaments[i - 1]
            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',

                compt?.attributes?.start_date
                    ? formatDate(compt?.attributes?.start_date)
                    : '-',
                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : '-',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
            ])
        }

        let comingCompetitionsContent = {
            startY: 50,
            head: [
                [
                    'Naziv',
                    'Organizator/Domaćin',
                    'Početak',
                    'Prijava do',
                    'Odjava do',
                    'Kategorija',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(comingCompetitionsContent)
        doc.save('Nadolazeća takmičenja.pdf')
        setShowFilters(false)
        setIsLoading(false)
    }

    const handlePdfExportFinished = async () => {
        const filteredTournaments = await getAllFinishedCompetitions(
            1,
            filterParams,
            true
        )

        setIsLoading(true)
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Završena takmičenja, Ukupno ${filteredTournaments?.length}`
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredTournaments?.length; i++) {
            const compt = filteredTournaments[i - 1]
            let status = false

            try {
                let tournamentReportResponse = await getTournamentReport(
                    compt?.attributes?.uuid
                )

                if (
                    tournamentReportResponse &&
                    tournamentReportResponse?.length > 0
                ) {
                    if (tournamentReportResponse[0]?.attributes?.is_verified) {
                        status = true
                    } else {
                        status = false
                    }
                } else {
                    status = false
                }
            } catch (error) {
                console.log(error)
            }

            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',

                compt?.attributes?.start_date
                    ? formatDate(compt?.attributes?.start_date)
                    : '-',
                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : '-',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
                status ? 'Verifikovan' : 'Nije verifikovan',
            ])
        }
        let finishedCompetitionsContent = {
            startY: 50,
            head: [
                [
                    'Naziv',
                    'Organizator/Domaćin',
                    'Početak',
                    'Prijava do',
                    'Odjava do',
                    'Kategorija',
                    'Status',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(finishedCompetitionsContent)
        doc.save('Završena takmičenja.pdf')
        setShowFilters(false)
        setIsLoading(false)
    }

    const handleTabSelect = (tab) => {
        setFilterParams(initialFilters)
        setSelectedClub(null)
        setShowFilters(false)
        setSearchValue('')
        setCurrentPage(1)
        setSelectedTab(tab)
    }

    return (
        <Row className="club-ranking-wrapper h-100">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    Takmičenja
                                </h2>
                                <p className="d-block d-md-none">Takmičenja</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/pocetna' }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Takmičenja
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center d-md-none">
                            <Button
                                type="text"
                                onClick={() => setShowFilters(true)}
                            >
                                <OutlinedFilter />
                            </Button>

                            <Link to="/takmicenja/podesavanje-takmicenja">
                                <Button type="text">
                                    <Settings />
                                </Button>
                            </Link>

                            <Link to="kreiraj-takmicenje">
                                <Button type="text">
                                    <Add />
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <Row className="d-flex align-items-center">
                            <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                <SearchInput
                                    placeholder="Pronađi takmičenje po imenu"
                                    onChange={(e) =>
                                        setSearchValue(e.target.value)
                                    }
                                />
                                <Button
                                    type="text"
                                    className="ms-2 d-none d-md-block"
                                    onClick={() => setShowFilters(true)}
                                >
                                    Filtriraj
                                </Button>

                                <Link
                                    to="/takmicenja/podesavanje-takmicenja"
                                    tabIndex="1"
                                    className="mx-2 d-none d-md-block"
                                >
                                    <Button type="secondary">
                                        Podešavanja
                                    </Button>
                                </Link>

                                <Link
                                    className="d-none d-md-block"
                                    to="kreiraj-takmicenje"
                                    tabIndex="1"
                                >
                                    <Button>Kreiraj takmičenje</Button>
                                </Link>
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row className="pt-3 m-0">
                    <Col className="p-0 tabular-navigation">
                        <Tabs
                            id="uncontrolled-tab-example"
                            onSelect={(tab) => {
                                setCurrentPage(1)
                                setSearchValue('')
                                handleTabSelect(tab)
                            }}
                        >
                            <Tab eventKey="nadolazeca" title="Nadolazeća">
                                <Row className="d-none d-md-block">
                                    <Col>
                                        <TableCompetitionsPagination
                                            data={comingCompetitions}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageCount={pageCount}
                                            total={total}
                                            className="d-none d-md-block"
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    </Col>
                                </Row>

                                <Row className="d-md-none">
                                    <Col>
                                        {!isArrayEmpty(comingCompetitions) ? (
                                            comingCompetitions.map(
                                                (result, index) => (
                                                    <CompetitionCard
                                                        key={index}
                                                        result={result}
                                                        isWhite={
                                                            index % 2 !== 0
                                                        }
                                                    />
                                                )
                                            )
                                        ) : (
                                            <NoDataForView
                                                title={`Nema nadolazećih takmičenja`}
                                                description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                                className="border-0"
                                                column
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="zavrsena" title="Završena">
                                <Row className="d-none d-md-block">
                                    <Col>
                                        <TableCompetitionsPagination
                                            data={finishedCompetitions}
                                            finished={true}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageCount={pageCount}
                                            total={total}
                                            className="d-none d-md-block"
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    </Col>
                                </Row>
                                <Row className="d-md-none">
                                    <Col>
                                        {!isArrayEmpty(finishedCompetitions) ? (
                                            finishedCompetitions.map(
                                                (result, index) => (
                                                    <CompetitionCard
                                                        key={index}
                                                        result={result}
                                                        isWhite={
                                                            index % 2 !== 0
                                                        }
                                                        finished={true}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <NoDataForView
                                                title={`Nema završenih takmičenja`}
                                                description={`Ovde je prikazana lista završenih takmičenja.`}
                                                className="border-0"
                                                column
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
            <Offcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col md={12} className="mt-3">
                            <RadioButton
                                name="Pol"
                                radioValues={genders}
                                onClick={(e) => {
                                    filterParams?.gender === e.target.value
                                        ? setFilterParams({
                                              ...filterParams,
                                              gender: '',
                                          })
                                        : null
                                }}
                                onChange={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        gender: e.target.value,
                                    })
                                }}
                                checkedValue={filterParams?.gender || ''}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <AsyncSelect
                                loadOptions={getClubList}
                                onChange={handleClubSelect}
                                label={'Izaberi klub'}
                                value={selectedClub}
                                placeholder={'Pretraži i izaberi klub...'}
                                noOptionsMessage={'Traženi klub ne postoji'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Region'}
                                value={filterParams?.region || ''}
                                placeholder="Region"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        region: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={
                                    regions
                                        ? regions
                                              .filter(
                                                  (region) => region !== 'TSS'
                                              )
                                              .reduce(
                                                  (accumulator, region) => [
                                                      ...accumulator,
                                                      region,
                                                  ],
                                                  filterParams?.region
                                                      ? ['Odselektuj']
                                                      : []
                                              )
                                        : []
                                }
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Konkurencija'}
                                className="yearDropdown"
                                value={filterParams?.competition || ''}
                                placeholder="Konkurencija"
                                handleSelect={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        competition:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }}
                                dropdownLinks={playersCompetition?.reduce(
                                    (accumulator, currentValue) => [
                                        ...accumulator,
                                        currentValue,
                                    ],
                                    filterParams?.competition
                                        ? [
                                              'Odselektuj',
                                              ...competitorCompetitions,
                                          ]
                                        : competitorCompetitions
                                )}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Kategorija'}
                                className="category-dropdown"
                                value={filterParams?.category || ''}
                                placeholder="Kategorija"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        category: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={tournamentCategories?.reduce(
                                    (accumulator, category) => [
                                        ...accumulator,
                                        category,
                                    ],
                                    filterParams?.category ? ['Odselektuj'] : []
                                )}
                            />
                        </Col>

                        {filterParams?.category === 'III' && (
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label={'Potkategorija'}
                                    value={filterParams?.sub_category || ''}
                                    placeholder="Potkategorija"
                                    handleSelect={(e) =>
                                        setFilterParams({
                                            ...filterParams,
                                            sub_category:
                                                e === 'Odselektuj' ? '' : e,
                                        })
                                    }
                                    dropdownLinks={tournamentSubCategories?.reduce(
                                        (accumulator, subcategory) => [
                                            ...accumulator,
                                            subcategory,
                                        ],
                                        filterParams?.sub_category
                                            ? ['Odselektuj']
                                            : []
                                    )}
                                />
                            </Col>
                        )}

                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Tip organizovanja takmičenja'}
                                placeholder="Izaberi tip organizovanja..."
                                value={
                                    filterParams?.tournament_organisation_type ||
                                    ''
                                }
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        tournament_organisation_type:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={tournamentOrganisationTypes?.reduce(
                                    (accumulator, category) => [
                                        ...accumulator,
                                        category,
                                    ],
                                    filterParams?.tournament_organisation_type
                                        ? ['Odselektuj']
                                        : []
                                )}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <Button
                                className="w-100"
                                onClick={() => {
                                    selectedTab === 'nadolazeca'
                                        ? getComingCompetitions(1)
                                        : getAllFinishedCompetitions(1)
                                }}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="secondary"
                                className="w-100"
                                onClick={
                                    selectedTab === 'nadolazeca'
                                        ? handlePdfExportComing
                                        : handlePdfExportFinished
                                }
                            >
                                Štampaj rezultate
                            </Button>
                            <Button
                                type="text"
                                className="w-100 mt-3"
                                onClick={() => handleDisableFiltering()}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Row>
    )
}

export default Competitions
