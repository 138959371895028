import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
    removePlayerFromTournament,
    updateTournamentPlayerParticipant,
} from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import {
    formatDate,
    formatImgUrl,
    handleTournamentSubcategories,
    numbersOnly,
} from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import Button from '../Button'
import Dropdown from '../Dropdown'
import Select from '../Select'
import TextField from '../TextField'

const tournamentStatuses = ['QA', 'DA', 'WC', 'WCQ', 'ALT', 'LL', 'Q', 'RET']

const ParticipantsRow = ({
    index,
    participant,
    enableRemove,
    handleGetPlayersForTournament,
    isDouble,
    competitionProfileData,
    isManualPointsChangeEnabled,
    setPlayersForTournamentList,
    handleAutoSeed = false,
    numberOfDAPlayers,
    numberOfQPlayers,
    numberOfWCPlayers,
    numberOfWCQPlayers,
    numberOfLLPlayers,
}) => {
    const { user, setShowCustomToast } = useAppContext()
    const [showStatusModal, setShowStatusModal] = useState(false)
    const [showRemoveCompetitorModal, setShowRemoveCompetitorModal] =
        useState(false)

    const [tournamentStatus, setTournamentStatus] = useState('QA')

    const handleRemoveTournamentParticipant = async (participantUuid) => {
        const removedPlayer = await removePlayerFromTournament(participantUuid)

        if (removedPlayer) {
            if (handleAutoSeed && isDouble !== true) {
                setShowRemoveCompetitorModal(false)
                handleAutoSeed()
            } else {
                setShowRemoveCompetitorModal(false)
                handleGetPlayersForTournament()
            }
        } else {
            setShowRemoveCompetitorModal(false)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Postoji kreiran meč sa ovim učesnikom.',
            })
        }
    }

    const handleUpdateStatus = async (participantUuid, selectedStatus) => {
        if (selectedStatus?.player_status?.status_name === 'DA' && !isDouble) {
            if (
                numberOfDAPlayers >=
                competitionProfileData?.attributes
                    ?.participants_expected_number -
                    competitionProfileData?.attributes?.wc_number -
                    competitionProfileData?.attributes?.number_of_qualified -
                    numberOfLLPlayers
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Popunjen je broj takmicara sa DA statusom',
                })
                setShowStatusModal(false)
                return
            }
        }

        if (selectedStatus?.player_status?.status_name === 'LL' && !isDouble) {
            if (
                numberOfDAPlayers +
                    numberOfWCPlayers +
                    numberOfQPlayers +
                    numberOfLLPlayers >=
                competitionProfileData?.attributes?.participants_expected_number
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Popunjen je broj takmicara sa LL statusom',
                })
                setShowStatusModal(false)
                return
            }
        }

        if (selectedStatus?.player_status?.status_name === 'Q' && !isDouble) {
            if (
                numberOfQPlayers >=
                    competitionProfileData?.attributes?.number_of_qualified ||
                numberOfDAPlayers +
                    numberOfQPlayers +
                    numberOfLLPlayers +
                    numberOfWCPlayers >=
                    competitionProfileData?.attributes
                        ?.participants_expected_number
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Popunjen je broj takmicara sa Q statusom',
                })
                setShowStatusModal(false)
                return
            }
        }

        if (selectedStatus?.player_status?.status_name === 'WC' && !isDouble) {
            if (
                numberOfWCPlayers >=
                    competitionProfileData?.attributes?.wc_number ||
                numberOfDAPlayers +
                    numberOfQPlayers +
                    numberOfLLPlayers +
                    numberOfWCPlayers >=
                    competitionProfileData?.attributes
                        ?.participants_expected_number
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Popunjen je broj takmicara sa WC statusom',
                })
                setShowStatusModal(false)
                return
            }
        }

        if (selectedStatus?.player_status?.status_name === 'WCQ' && !isDouble) {
            if (
                numberOfWCQPlayers >=
                competitionProfileData?.attributes?.wc_qualification_number
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Popunjen je broj takmicara sa WCQ statusom',
                })
                setShowStatusModal(false)
                return
            }
        }

        if (selectedStatus?.player_status?.status_name === 'RET') {
            selectedStatus.players_tournament_seed = 128
        }

        const updatedStatus = await updateTournamentPlayerParticipant(
            participantUuid,
            selectedStatus
        )

        if (updatedStatus) {
            if (handleAutoSeed && isDouble !== true) {
                setShowStatusModal(false)
                if (selectedStatus?.player_status?.status_name === 'RET') {
                    handleGetPlayersForTournament()
                } else {
                    handleAutoSeed()
                }
            } else {
                setShowStatusModal(false)
                handleGetPlayersForTournament()
            }
        }
    }

    const handleUpdateSubtournamentName = async (
        participantUuid,
        selectedSubtournament
    ) => {
        const updatedSubtournamentName =
            await updateTournamentPlayerParticipant(
                participantUuid,
                selectedSubtournament
            )

        if (updatedSubtournamentName) {
            handleGetPlayersForTournament()
        }
    }

    return (
        <>
            <tr>
                <td
                    className="align-middle first-column"
                    style={{ width: '70px', minWidth: '70px' }}
                >
                    <p>{index + 1}</p>
                </td>
                <td style={{ minWidth: '140px' }}>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <img
                                className="competitor-img me-2"
                                src={
                                    participant?.attributes?.player_1
                                        ?.attributes?.person_info?.picture
                                        ? participant?.attributes?.player_1
                                              ?.attributes?.person_info?.picture
                                        : generateAvatar(
                                              `${
                                                  participant?.attributes
                                                      ?.player_1?.attributes
                                                      ?.person_info
                                                      ?.given_name || ''
                                              } ${
                                                  participant?.attributes
                                                      ?.player_1?.attributes
                                                      ?.person_info
                                                      ?.family_name || ''
                                              }`
                                          )
                                }
                            />
                            <div>
                                <Link
                                    to={`/takmicari/${participant?.attributes?.player_1?.attributes?.uuid}`}
                                >
                                    <p className="link">
                                        {
                                            participant?.attributes?.player_1
                                                ?.attributes?.person_info
                                                ?.given_name
                                        }{' '}
                                        {
                                            participant?.attributes?.player_1
                                                ?.attributes?.person_info
                                                ?.family_name
                                        }
                                    </p>
                                </Link>
                            </div>
                        </div>

                        {isDouble && (
                            <div className="d-flex align-items-center mt-2">
                                <img
                                    className="competitor-img me-2"
                                    src={
                                        participant?.attributes?.player_2
                                            ?.attributes?.person_info?.picture
                                            ? participant?.attributes?.player_2
                                                  ?.attributes?.person_info
                                                  ?.picture
                                            : generateAvatar(
                                                  `${participant?.attributes?.player_2?.attributes?.person_info?.given_name}} ${participant?.attributes?.player_2?.attributes?.person_info?.family_name}`
                                              )
                                    }
                                />
                                <div>
                                    <Link
                                        to={`/takmicari/${participant?.attributes?.player_2?.attributes?.uuid}`}
                                    >
                                        <p className="link">
                                            {
                                                participant?.attributes
                                                    ?.player_2?.attributes
                                                    ?.person_info?.given_name
                                            }{' '}
                                            {
                                                participant?.attributes
                                                    ?.player_2?.attributes
                                                    ?.person_info?.family_name
                                            }
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </td>

                <td
                    style={{ minWidth: '170px' }}
                    className="d-flex align-items-center justify-content-start"
                >
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            {participant?.attributes?.player_1_club?.attributes
                                ?.name ? (
                                <>
                                    <img
                                        src={
                                            participant?.attributes
                                                ?.player_1_club?.attributes
                                                ?.logo?.data
                                                ? formatImgUrl(
                                                      participant?.attributes
                                                          ?.player_1_club
                                                          ?.attributes?.logo
                                                          ?.data?.attributes
                                                          ?.url
                                                  )
                                                : generateAvatar(
                                                      participant?.attributes
                                                          ?.player_1_club
                                                          ?.attributes?.name
                                                  )
                                        }
                                        alt={`Logo kluba`}
                                        className="competitor-img"
                                    />
                                    <Link
                                        to={`/klubovi/${participant?.attributes?.player_1_club?.attributes?.uuid}`}
                                    >
                                        <p className="ms-2 link">
                                            {
                                                participant?.attributes
                                                    ?.player_1_club?.attributes
                                                    ?.name
                                            }
                                        </p>
                                    </Link>
                                </>
                            ) : (
                                <p>-</p>
                            )}
                        </div>

                        {isDouble ? (
                            participant?.attributes?.player_2_club?.attributes
                                ?.name ? (
                                <div className="d-flex align-items-center mt-2">
                                    <img
                                        src={
                                            participant?.attributes
                                                ?.player_2_club?.attributes
                                                ?.logo?.data
                                                ? formatImgUrl(
                                                      participant?.attributes
                                                          ?.player_2_club
                                                          ?.attributes?.logo
                                                          ?.data?.attributes
                                                          ?.url
                                                  )
                                                : generateAvatar(
                                                      participant?.attributes
                                                          ?.player_2_club
                                                          ?.attributes?.name
                                                  )
                                        }
                                        alt={`Logo kluba`}
                                        className="competitor-img"
                                    />
                                    <Link
                                        to={`/klubovi/${participant?.attributes?.player_2_club?.attributes?.uuid}`}
                                    >
                                        <p className="ms-2 link">
                                            {
                                                participant?.attributes
                                                    ?.player_2_club?.attributes
                                                    ?.name
                                            }
                                        </p>
                                    </Link>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center">
                                    <p>-</p>
                                </div>
                            )
                        ) : null}
                    </div>
                </td>

                <td style={{ minWidth: '114px' }}>
                    <div className="d-flex flex-column justify-content-center">
                        <p className="p-columns">
                            {participant?.attributes?.player_1?.attributes
                                ?.person_info?.birthdate
                                ? formatDate(
                                      participant?.attributes?.player_1
                                          ?.attributes?.person_info?.birthdate
                                  )
                                : '-'}
                        </p>
                        {isDouble && (
                            <p className="p-columns mt-2">
                                {participant?.attributes?.player_2?.attributes
                                    ?.person_info?.birthdate
                                    ? formatDate(
                                          participant?.attributes?.player_2
                                              ?.attributes?.person_info
                                              ?.birthdate
                                      )
                                    : '-'}
                            </p>
                        )}
                    </div>
                </td>

                <td style={{ minWidth: '80px' }}>
                    <div className="d-flex flex-column justify-content-center">
                        <p className="p-columns">
                            {participant?.attributes?.player_1?.attributes
                                ?.atp_wta_rank || '-'}
                        </p>

                        {isDouble && (
                            <p className="p-columns">
                                {participant?.attributes?.player_2?.attributes
                                    ?.atp_wta_rank || '-'}
                            </p>
                        )}
                    </div>
                </td>
                <td style={{ minWidth: '60px' }}>
                    <div className="d-flex flex-column justify-content-center">
                        <p className="p-columns">
                            {participant?.attributes?.player_1?.attributes
                                ?.itf_rank || '-'}
                        </p>

                        {isDouble && (
                            <p className="p-columns">
                                {participant?.attributes?.player_2?.attributes
                                    ?.itf_rank || '-'}
                            </p>
                        )}
                    </div>
                </td>

                <td style={{ minWidth: '60px' }}>
                    <div className="d-flex flex-column justify-content-center">
                        <p className="p-columns">
                            {participant?.attributes?.player_1?.attributes
                                ?.eta_14_rank || '-'}
                        </p>

                        {isDouble && (
                            <p className="p-columns">
                                {participant?.attributes?.player_2?.attributes
                                    ?.eta_14_rank || '-'}
                            </p>
                        )}
                    </div>
                </td>

                <td style={{ minWidth: '60px' }}>
                    <div className="d-flex flex-column justify-content-center">
                        <p className="p-columns">
                            {participant?.attributes?.player_1?.attributes
                                ?.eta_16_rank || '-'}
                        </p>

                        {isDouble && (
                            <p className="p-columns">
                                {participant?.attributes?.player_2?.attributes
                                    ?.eta_16_rank || '-'}
                            </p>
                        )}
                    </div>
                </td>

                <td style={{ minWidth: '60px' }}>
                    <div className="d-flex flex-column justify-content-center">
                        <p className="p-columns">
                            {participant?.attributes?.player_1_ranking_number ||
                                '-'}
                        </p>

                        {isDouble && (
                            <p className="p-columns">
                                {participant?.attributes
                                    ?.player_2_ranking_number || ''}
                            </p>
                        )}
                    </div>
                </td>

                {!competitionProfileData?.attributes
                    ?.tournament_have_grand_prix &&
                    competitionProfileData?.attributes?.tournament_type
                        ?.tournament_type_name !== 'Ekipno' && (
                        <td style={{ minWidth: '80px' }}>
                            <div className="d-flex flex-column justify-content-center">
                                <p className="p-columns">
                                    {participant?.attributes?.player_status
                                        ?.status_name
                                        ? participant?.attributes?.player_status
                                              ?.status_name
                                        : '-'}
                                </p>
                            </div>
                        </td>
                    )}
                {competitionProfileData?.attributes
                    ?.tournament_have_grand_prix ? (
                    user?.role?.type !== 'competitor' ? (
                        <td style={{ minWidth: '85px' }}>
                            <div>
                                <Dropdown
                                    placeholder="Izaberi grupu"
                                    dropdownLinks={handleTournamentSubcategories(
                                        64
                                    )}
                                    value={
                                        participant?.attributes
                                            ?.subtournament_name
                                            ?.tournament_subcategory_name
                                    }
                                    handleSelect={(e) => {
                                        handleUpdateSubtournamentName(
                                            participant?.attributes?.uuid,
                                            {
                                                subtournament_name: {
                                                    tournament_subcategory_name:
                                                        e,
                                                },
                                            }
                                        )
                                    }}
                                />
                            </div>
                        </td>
                    ) : (
                        <td>
                            <p className="p-columns">
                                {
                                    participant?.attributes?.subtournament_name
                                        ?.tournament_subcategory_name
                                }
                            </p>
                        </td>
                    )
                ) : null}
                <td style={{ minWidth: '85px' }}>
                    <div className="d-flex flex-column justify-content-center">
                        {isManualPointsChangeEnabled ? (
                            <TextField
                                type="tel"
                                min={0}
                                value={participant?.attributes?.ranking_points}
                                pattern="[0-9]*"
                                onChange={(e) => {
                                    numbersOnly(e)
                                    setPlayersForTournamentList((prev) =>
                                        prev.map((player) => {
                                            if (
                                                player?.attributes?.uuid !==
                                                participant?.attributes?.uuid
                                            ) {
                                                return player
                                            }
                                            return {
                                                ...player,
                                                attributes: {
                                                    ...player.attributes,
                                                    ranking_points:
                                                        +e.target.value,
                                                },
                                            }
                                        })
                                    )
                                }}
                            />
                        ) : (
                            <p className="p-columns">
                                {participant?.attributes?.ranking_points}
                            </p>
                        )}
                    </div>
                </td>
                {!competitionProfileData?.attributes
                    ?.tournament_have_grand_prix &&
                    competitionProfileData?.attributes?.tournament_type
                        ?.tournament_type_name !== 'Ekipno' && (
                        <td className="align-middle">
                            {(user?.role?.type === 'admin' ||
                                user?.role?.type === 'referee') &&
                                participant?.attributes?.uuid && (
                                    <p
                                        className="link"
                                        onClick={() => setShowStatusModal(true)}
                                    >
                                        Izmeni status
                                    </p>
                                )}
                        </td>
                    )}

                {enableRemove && (
                    <td className="align-middle">
                        {user?.role?.type === 'admin' && (
                            <p
                                className="red-link"
                                onClick={() =>
                                    setShowRemoveCompetitorModal(true)
                                }
                            >
                                Ukloni
                            </p>
                        )}
                    </td>
                )}
            </tr>

            <Modal
                size="md"
                centered
                show={showStatusModal}
                onHide={() => setShowStatusModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Odaberite status takmičara</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-3">
                        Selektovani takmičar:{' '}
                        <span>
                            <Link
                                to={`/takmicari/${participant?.attributes?.player_1?.attributes?.uuid}`}
                                className="link"
                            >
                                {
                                    participant?.attributes?.player_1
                                        ?.attributes?.person_info?.given_name
                                }{' '}
                                {
                                    participant?.attributes?.player_1
                                        ?.attributes?.person_info?.family_name
                                }
                            </Link>
                        </span>
                    </p>
                    <Select
                        value={tournamentStatus}
                        onChange={(e) => setTournamentStatus(e.target.value)}
                        data={tournamentStatuses}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleUpdateStatus(participant?.attributes?.uuid, {
                                player_status: {
                                    status_name: tournamentStatus,
                                },
                            })
                        }
                    >
                        Odaberi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                centered
                show={showRemoveCompetitorModal}
                onHide={() => setShowRemoveCompetitorModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Uklanjanje takmičara</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isDouble ? (
                        <p>
                            Da li ste sigurni da želite da uklonite par (
                            <Link
                                to={`/takmicari/${participant?.attributes?.player_1?.attributes?.uuid}`}
                                className="link"
                            >
                                {
                                    participant?.attributes?.player_1
                                        ?.attributes?.person_info?.given_name
                                }{' '}
                                {
                                    participant?.attributes?.player_1
                                        ?.attributes?.person_info?.family_name
                                }
                            </Link>{' '}
                            -{' '}
                            <Link
                                to={`/takmicari/${participant?.attributes?.player_2?.attributes?.uuid}`}
                                className="link"
                            >
                                {
                                    participant?.attributes?.player_2
                                        ?.attributes?.person_info?.given_name
                                }{' '}
                                {
                                    participant?.attributes?.player_2
                                        ?.attributes?.person_info?.family_name
                                }
                            </Link>
                            ) sa ovog takmičenja?
                        </p>
                    ) : (
                        <p>
                            Da li ste sigurni da želite da uklonite takmičara (
                            <Link
                                to={`/takmicari/${participant?.attributes?.player_1?.attributes?.uuid}`}
                                className="link"
                            >
                                {
                                    participant?.attributes?.player_1
                                        ?.attributes?.person_info?.given_name
                                }{' '}
                                {
                                    participant?.attributes?.player_1
                                        ?.attributes?.person_info?.family_name
                                }
                            </Link>
                            ) sa ovog takmičenja?
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowRemoveCompetitorModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleRemoveTournamentParticipant(
                                participant?.attributes?.uuid
                            )
                        }}
                    >
                        Ukloni
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ParticipantsRow
