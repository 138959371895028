import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../../../../../components/Button'
import TournamentMatchCard from '../../../../../../../components/TournamentMatchCard'
import {
    autoGenerateGrandPrixDraw,
    getGroupMatches,
} from '../../../../../../../lib/api'
import { useAppContext } from '../../../../../../../lib/contextLib'

const GroupMatches = ({ drawToShow, tournament }) => {
    const { user, setSgpDataForPDF } = useAppContext()
    const [groupMatches, setGroupMatches] = useState([])

    const handleGetGroupMatches = async () => {
        if (drawToShow?.round_number && drawToShow?.subtournament_name) {
            try {
                const groupMatchesData = await getGroupMatches({
                    tournament_uuid: tournament?.attributes?.uuid,
                    is_double: drawToShow?.is_double,
                    gender: { gender_name: drawToShow?.gender?.gender_name },
                    draw_type: {
                        draw_type_name: drawToShow?.draw_type?.draw_type_name,
                    },
                    round_number: drawToShow?.round_number,
                    subtournament_name:
                        drawToShow?.round_number === 5
                            ? null
                            : drawToShow?.subtournament_name,
                })
                if (groupMatchesData) {
                    setGroupMatches(groupMatchesData)

                    let { round_number, ...rest } = drawToShow

                    setSgpDataForPDF((prev) => {
                        // Check if there is an existing entry with the same round_number
                        const existingIndex = prev.groupMatches.findIndex(
                            (item) =>
                                item?.round_number === drawToShow?.round_number
                        )

                        let updatedGroupMatches
                        if (existingIndex > -1) {
                            // If found, replace the existing entry
                            updatedGroupMatches = [...prev?.groupMatches]
                            updatedGroupMatches[existingIndex] = {
                                round_number: drawToShow?.round_number,
                                matches: groupMatchesData,
                            }
                        } else {
                            // If not found, append the new entry
                            updatedGroupMatches = [
                                ...prev?.groupMatches,
                                {
                                    round_number: drawToShow?.round_number,
                                    matches: groupMatchesData,
                                },
                            ]
                        }

                        updatedGroupMatches.sort(
                            (a, b) => a?.round_number - b?.round_number
                        )

                        return {
                            groupMatches: updatedGroupMatches,
                            drawToShow: { ...rest },
                            tournament:
                                prev?.tournament?.id === tournament?.id
                                    ? prev?.tournament
                                    : tournament,
                        }
                    })
                }
            } catch (error) {
                throw new Error(error)
            }
        }
    }

    const handleGenerateMatches = async () => {
        try {
            const generatedMatches = await autoGenerateGrandPrixDraw(
                tournament?.attributes?.uuid,
                drawToShow?.gender?.gender_name,
                drawToShow.round_number,
                drawToShow?.subtournament_name,
                drawToShow?.is_double
            )

            if (generatedMatches) {
                handleGetGroupMatches()
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetGroupMatches()
    }, [
        drawToShow?.round_number,
        drawToShow?.subtournament_name,
        drawToShow?.is_double,
        drawToShow?.gender?.gender_name,
    ])

    return (
        <Row>
            <Col>
                {groupMatches?.length === 0 && (
                    <Row>
                        <Col>
                            {user?.role?.type !== 'competitor' && (
                                <Button
                                    onClick={() => {
                                        handleGenerateMatches()
                                    }}
                                >
                                    Kreiraj mečeve
                                </Button>
                            )}
                        </Col>
                    </Row>
                )}
                <Row className="gx-5 gy-3 mt-4">
                    {groupMatches?.map((match, index) => {
                        return (
                            <Col xs={12} md={4} key={index}>
                                <TournamentMatchCard
                                    matchDetails={{
                                        round_number:
                                            match?.attributes?.round_number,
                                        tournament_uuid:
                                            match?.attributes?.tournament_uuid,
                                        match,
                                        ...drawToShow,
                                    }}
                                    onChange={handleGetGroupMatches}
                                    onDelete={handleGetGroupMatches}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </Col>
        </Row>
    )
}

export default GroupMatches
