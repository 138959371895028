import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import StatusField from './statusField'
import {
    checkClubMemberships,
    handleShowContactPerson,
    formatImgUrl,
} from '../../../lib/helpers'
import { generateAvatar } from '../../../utils/generateAvatar'
import { useAppContext } from '../../../lib/contextLib'

const ClubTableRow = ({ club }) => {
    const { user } = useAppContext()
    const [clubStatus, setClubStatus] = useState('Neaktivan')

    const navigate = useNavigate()

    const handleClubStatus = async (clubUuid) => {
        try {
            const clubStatusResponse = await checkClubMemberships(clubUuid)

            if (!clubStatusResponse) return

            setClubStatus(clubStatusResponse)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleClubStatus(club?.uuid)
    }, [club])

    return (
        <tr className="club">
            <td className="link link-align-end club-list-first-row">
                <div className="d-flex align-items-center justify-content-start">
                    <div className="img-wrapper">
                        <img
                            src={
                                club?.logo?.data
                                    ? formatImgUrl(
                                          club?.logo?.data?.club?.formats
                                              ?.thumbnail?.url
                                      )
                                    : generateAvatar(club?.name)
                            }
                            alt={`${club?.name} logo`}
                            width={'24px'}
                            height={'24px'}
                        />
                    </div>
                    <Link to={`/klubovi/${club?.uuid}`}>
                        <p className="text-elipsis link">{club?.name}</p>
                    </Link>
                </div>
            </td>
            <td style={{ minWidth: '118px' }}>
                <p>{club?.short_name || '-'}</p>
            </td>
            <td style={{ minWidth: '118px' }}>
                <p>{club?.region?.region_name || '-'}</p>
            </td>
            <td style={{ minWidth: '118px' }}>
                <p>{club?.email || '-'}</p>
            </td>
            <td style={{ minWidth: '118px' }}>
                <p>{handleShowContactPerson(club?.staff)}</p>
            </td>
            <td style={{ minWidth: '118px' }}>
                <p>{club?.phone || '-'}</p>
            </td>
            <StatusField clubStatus={clubStatus} />
            {user?.role?.type === 'admin' ? (
                <td
                    className="link"
                    onClick={() => {
                        navigate(`/klubovi/${club?.uuid}/izmeni-podatke-kluba`)
                    }}
                    id={club?.uuid}
                >
                    Izmeni
                </td>
            ) : null}
        </tr>
    )
}

export default ClubTableRow
