import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { getTournamentReport } from '../../../lib/api'
import { formatDate, formatImgUrl, pillHandler } from '../../../lib/helpers'
import { generateAvatar } from '../../../utils/generateAvatar'
import Pill from '../../Pill'
import { useAppContext } from '../../../lib/contextLib'

const CompetitionCard = ({ isWhite, result, finished }) => {
    let navigate = useNavigate()
    const { user } = useAppContext()
    const [hasReport, setHasReport] = useState(false)

    const hasTournamentReport = async (tournamentUuid) => {
        try {
            let tournamentReportResponse = await getTournamentReport(
                tournamentUuid
            )

            if (
                tournamentReportResponse &&
                tournamentReportResponse?.length > 0
            ) {
                setHasReport(tournamentReportResponse[0])
            } else {
                setHasReport(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleEdit = () => {
        navigate(`${result?.attributes?.uuid}/izmeni-takmicenje`)
    }

    const setLocalStorageActiveTab = (activeTab) => {
        localStorage.setItem('competitionActiveTab', activeTab)
    }

    useEffect(() => {
        hasTournamentReport(result?.attributes?.uuid)
    }, [result])

    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Naziv</h4>
                            </Col>
                            <Col xs={7}>
                                <Link
                                    to={`/takmicenja/${result?.attributes?.uuid}`}
                                >
                                    <p className="link">
                                        {result?.attributes?.name}
                                    </p>
                                </Link>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Organizator</h4>
                            </Col>
                            <Col xs={7} className="d-flex align-items-center">
                                {result?.attributes?.club_organizer?.attributes
                                    ?.name ? (
                                    <>
                                        <img
                                            src={
                                                result?.attributes
                                                    ?.club_organizer?.attributes
                                                    ?.logo?.data
                                                    ? formatImgUrl(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                            width={'24px'}
                                            height={'24px'}
                                            style={{
                                                maxHeight: '24px',
                                                maxWidth: '24px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Link
                                            to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                        >
                                            <p className="ms-2 link">
                                                {
                                                    result?.attributes
                                                        ?.club_organizer
                                                        ?.attributes?.name
                                                }
                                            </p>
                                        </Link>
                                    </>
                                ) : (
                                    '-'
                                )}
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Početak</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {formatDate(result?.attributes?.start_date)}
                                </p>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Prijava do</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {formatDate(
                                        result?.attributes
                                            ?.application_deadline_date
                                    )}
                                </p>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Odjava do</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {formatDate(
                                        result?.attributes
                                            ?.checkout_deadline_date
                                    )}
                                </p>
                            </Col>
                        </Row>

                        <Row className={`car-row align-items-center`}>
                            <Col xs={5}>
                                <h4>Kategorija</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {result?.attributes?.tournament_category
                                        ?.tournament_category_name
                                        ? result?.attributes
                                              ?.tournament_category
                                              ?.tournament_category_name
                                        : '-'}
                                </p>
                            </Col>
                        </Row>

                        {!finished && user?.role?.type === 'admin' && (
                            <Row
                                className={`car-row ${
                                    !finished && 'border-bottom-0'
                                } align-items-center`}
                            >
                                <Col xs={12}>
                                    <p
                                        className="link"
                                        onClick={handleEdit}
                                        id={result?.id}
                                    >
                                        Izmeni
                                    </p>
                                </Col>
                            </Row>
                        )}

                        {finished && (
                            <>
                                <Row className={`car-row align-items-center`}>
                                    <Col xs={5}>
                                        <h4>Status</h4>
                                    </Col>
                                    <Col xs={7}>
                                        {pillHandler(
                                            hasReport?.attributes?.is_verified
                                        )}
                                    </Col>
                                </Row>

                                {hasReport?.attributes?.is_verified === false &&
                                    user?.role?.type !== 'competitor' && (
                                        <Row className="car-row border-bottom-0 align-items-center">
                                            <Col>
                                                <Link
                                                    to={`/takmicenja/${result?.attributes?.uuid}`}
                                                    onClick={() =>
                                                        setLocalStorageActiveTab(
                                                            'izvestaj'
                                                        )
                                                    }
                                                >
                                                    <p className="link">
                                                        Verifikuj
                                                    </p>
                                                </Link>
                                            </Col>
                                        </Row>
                                    )}
                            </>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CompetitionCard
