import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../Button'

const ConfirmationModal = ({
    modalTitle = 'Potvrda brisanja',
    modalText = 'Jeste li sigurni da želite obrisati profil?',
    show,
    onClick,
    onHide,
    hideButtons,
    firstButtonText = 'Odustani',
    secondButtonText = 'Obriši',
    disabledConfirm = false,
    children,
    dialogClassName = false,
}) => {
    return (
        <Modal
            size="md"
            centered
            show={show}
            onHide={onHide}
            dialogClassName={dialogClassName ? dialogClassName : null}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>{modalTitle}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-2">{modalText}</p>
                {children}
            </Modal.Body>
            {!hideButtons && (
                <Modal.Footer>
                    <Button type="text" onClick={onHide}>
                        {firstButtonText}
                    </Button>
                    <Button onClick={onClick} disabled={disabledConfirm}>
                        {secondButtonText}
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default ConfirmationModal
