import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import Add from '../../assets/svg/Add'
import OutlinedFilter from '../../assets/svg/OutlinedFilter'
import Button from '../Button'
import ClubListTableCard from '../ClubListTableCard'
import SearchInput from '../SearchInput'
import { getClubs } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import { debounce } from '../../lib/helpers'
import PaginationWrapper from '../Pagination'
import { RowsPerPageOptions } from '../RowsPerPageDropdown'
import ClubTableRow from './subcomponents/clubTableRow'
import FiltersCanvas from './subcomponents/filtersCanvas'

import './styles.scss'

const searchInputID = `searchInput-clubs`

const ClubList = () => {
    const { setIsLoading, user } = useAppContext()
    const navigate = useNavigate()
    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    //pagination rows number
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const initialFilters = {
        region: '',
    }

    const [filterParams, setFilterParams] = useState(initialFilters)
    const [clubs, setClubs] = useState([])

    const [showFilters, setShowFilters] = useState(false)

    const getAllClubs = async (page, presetFilters, allEntries = false) => {
        let search = document.getElementById(searchInputID)
        setIsLoading(true)
        if (search) {
            let name = search.value

            let searchParams = {
                name: name,
            }

            let fields = [
                'name',
                'short_name',
                'email',
                'phone',
                'region',
                'establishment_date',
                'uuid',
            ]

            const { clubs, pgCount, total } = await getClubs(
                fields,
                searchParams,
                presetFilters ? presetFilters : filterParams,
                page,
                allEntries,
                rowsPerPage
            )

            if (clubs) {
                if (allEntries) {
                    setIsLoading(false)
                    return clubs
                } else {
                    setClubs(clubs)
                    setPageCount(pgCount)
                    setTotal(total)
                    setCurrentPage(page)
                    setShowFilters(false)
                    setIsLoading(false)
                }
            }
        }
        setIsLoading(false)
    }

    // Remove filters
    const handleRemoveFilters = () => {
        setFilterParams(initialFilters)
        setShowFilters(false)
        getAllClubs(1, initialFilters)
        setCurrentPage(1)
        setPageCount(1)
    }

    useEffect(() => {
        getAllClubs(currentPage)
    }, [currentPage, rowsPerPage])

    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        getAllClubs(1)
    }, 1000)

    const handleCreate = (e) => {
        //? Here goes code for handling Club edit
        navigate(`/klubovi/kreiraj-klub`)
    }

    return (
        <Row className="clubs-wrapper">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col className="">
                                <h2 className="d-none d-md-block">Klubovi</h2>
                                <p className="d-block d-md-none">Klubovi</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/pocetna' }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Klubovi
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center d-md-none">
                            <Button
                                type="text"
                                onClick={() => setShowFilters(true)}
                            >
                                <OutlinedFilter />
                            </Button>

                            <Button type="text" onClick={handleCreate}>
                                <Add />
                            </Button>
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <Row className="d-flex align-items-center">
                            <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                <SearchInput
                                    id={searchInputID}
                                    placeholder="Pronadji klub po nazivu"
                                    onChange={(e) =>
                                        updateDebounceText(e.target.value)
                                    }
                                />
                                <Button
                                    type="secondary"
                                    className="mx-2 d-none d-md-block"
                                    onClick={() => setShowFilters(true)}
                                >
                                    Filtriraj
                                </Button>

                                <Button
                                    className="d-none d-md-flex"
                                    onClick={handleCreate}
                                >
                                    Kreiraj klub
                                </Button>
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className="pt-3">
                        <Table className="d-none d-md-block">
                            <thead>
                                <tr>
                                    <th className="club-list-first-row">
                                        <h5>Klub</h5>
                                    </th>
                                    <th style={{ minWidth: '118px' }}>
                                        <h5>Skraćeno ime</h5>
                                    </th>
                                    <th style={{ minWidth: '118px' }}>
                                        <h5>Region</h5>
                                    </th>
                                    <th style={{ minWidth: '118px' }}>
                                        <h5>Email</h5>
                                    </th>
                                    <th style={{ minWidth: '118px' }}>
                                        <h5>Kontakt osoba</h5>
                                    </th>
                                    <th style={{ minWidth: '118px' }}>
                                        <h5>Telefon</h5>
                                    </th>
                                    <th style={{ minWidth: '170px' }}>
                                        <h5>Status</h5>
                                    </th>
                                    {user?.role?.type === 'admin' ? (
                                        <th></th>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {clubs?.map(({ id, attributes }, index) => (
                                    <ClubTableRow
                                        key={index}
                                        club={attributes}
                                    />
                                ))}
                            </tbody>
                        </Table>
                        <PaginationWrapper
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageCount={pageCount}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                        <ClubListTableCard
                            data={clubs}
                            className={'d-md-none d-flex background-table-card'}
                        />
                    </Col>
                </Row>
            </Col>

            <FiltersCanvas
                open={showFilters}
                onClose={() => setShowFilters(false)}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                getAllClubs={getAllClubs}
                removeFilters={handleRemoveFilters}
            />
        </Row>
    )
}

export default ClubList
