import jsPDF from 'jspdf'
import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DocumentTextOutlined from '../../../../assets/svg/DocumentTextOutlined'
import PrintOutlined from '../../../../assets/svg/PrintOutlined'
import Settings from '../../../../assets/svg/Settings'
import Button from '../../../../components/Button'
import ClubsRankingTable from '../../../../components/ClubsRankingTable'
import ClubsAssemblyRankingTable from '../../../../components/ClubsRankingTable/ClubAssemblyRankingTable'
import MultiSelectSearch from '../../../../components/MultiSelectSearch'
import ClubAssemblyCard from '../../../../components/RankingTableCards/ClubAssemblyCard'
import ClubCard from '../../../../components/RankingTableCards/ClubCard'
import { RowsPerPageOptions } from '../../../../components/RowsPerPageDropdown'
import {
    calculateClubRank,
    filterRankingList,
    getClubsAssemblyRankingList,
    getClubsPlayers,
    getClubsRankingList,
    getCodebook,
    getSingleClub,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import { formatDate } from '../../../../lib/helpers'
import { font } from '../../Competitions/CompetitionProfile/font'
import UpdateRankingListModal from '../CompetitorsRankingByCategory/updateRankingListModal'
import { exportCSV } from './exportCsv'
import './styles.scss'

const ClubsRanking = () => {
    const { user, setShowCustomToast, setIsLoading } = useAppContext()
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])
    const [refreshData, setRefreshData] = useState(false)

    const [regions, setRegions] = useState([])

    const handleCodebook = async () => {
        await getCodebook('codebooks.region', setRegions)
    }

    const initialFilters = {
        region: '',
    }

    const [filterParams, setFilterParams] = useState(initialFilters)
    const [showFilters, setShowFilters] = useState(false)

    const [clubsList, setClubsList] = useState([])
    const [showUpdateRankingListModal, setShowUpdateRankingListModal] =
        useState(false)
    const [activeTab, setActiveTab] = useState(
        localStorage.getItem('clubRankingListActiveTab')
            ? localStorage.getItem('clubRankingListActiveTab')
            : 'by-players'
    )

    const handleGetClubsRankingList = async (
        allEntries = false,
        filters = false
    ) => {
        try {
            setIsLoading(true)
            let clubs, pgCount, total
            if (activeTab === 'assembly') {
                let data

                if (filters) {
                    data = await filterRankingList(
                        { ...filterParams, list_to_look: activeTab },
                        allEntries ? 1 : currentPage,
                        allEntries,
                        rowsPerPage
                    )
                } else {
                    data = await getClubsAssemblyRankingList(
                        allEntries ? 1 : currentPage,
                        allEntries,
                        rowsPerPage
                    )
                }

                clubs = data?.clubs
                pgCount = data?.pgCount
                total = data?.total
            } else {
                let data

                if (filters) {
                    data = await filterRankingList(
                        { ...filterParams, list_to_look: activeTab },
                        allEntries ? 1 : currentPage,
                        allEntries,
                        rowsPerPage
                    )
                } else {
                    data = await getClubsRankingList(
                        allEntries ? 1 : currentPage,
                        activeTab === 'by-players-current-year' ? true : false,
                        allEntries,
                        rowsPerPage
                    )
                }

                clubs = data?.clubs
                pgCount = data?.pgCount
                total = data?.total
            }

            if (clubs?.length < 1) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Klubovi nemaju igrače ili parametre za bodovanje.',
                })
                setIsLoading(false)
                setClubsList([])
                setCurrentPage(1)
            }

            const promises = clubs?.map(async (club) => {
                const uuid = club?.attributes?.club_uuid

                if (!uuid) return null

                const singleClub = await getSingleClub(uuid)

                if (!singleClub) return null

                let totalPlayers = 0

                if (activeTab !== 'assembly') {
                    const { total: totalPlayersClubs } = await getClubsPlayers(
                        club?.attributes?.club_uuid
                    )

                    totalPlayers = totalPlayersClubs
                }

                return activeTab === 'assembly'
                    ? {
                          ...club,
                          attributes: {
                              ...club?.attributes,
                          },
                          club_data: {
                              ...singleClub,
                          },
                      }
                    : {
                          ...club,
                          attributes: {
                              ...club?.attributes,
                              total_players: totalPlayers,
                          },
                          club_data: {
                              ...singleClub,
                          },
                      }
            })

            const updatedList = await Promise.all(promises)

            if (updatedList) {
                if (allEntries) {
                    setIsLoading(false)
                    setShowFilters(false)
                    return updatedList
                } else {
                    setPageCount(pgCount)
                    setTotal(total)
                    setClubsList(updatedList)
                    setIsLoading(false)
                    setShowFilters(false)
                }
            }
        } catch (error) {
            setIsLoading(false)
            setShowFilters(false)
        }
    }

    const handleUpdateRankingList = async () => {
        try {
            setIsLoading(true)
            let calculatedRank = await calculateClubRank()

            if (calculatedRank) {
                handleGetClubsRankingList()
                setShowUpdateRankingListModal(true)
            }
            setIsLoading(false)
        } catch (error) {
            console.error(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        handleCodebook()
        handleGetClubsRankingList(
            false,
            filterParams?.hasOwnProperty('region') && filterParams?.region
                ? filterParams
                : false
        )
    }, [currentPage, rowsPerPage, activeTab])

    useEffect(() => {
        if (refreshData) {
            if (
                filterParams?.hasOwnProperty('region') &&
                filterParams?.region
            ) {
                handleGetClubsRankingList(false, filterParams)
            } else {
                handleGetClubsRankingList()
            }
            setRefreshData(false)
        }
    }, [refreshData])

    useEffect(() => {
        setFilterParams(initialFilters)
        setCurrentPage(1)
    }, [activeTab])

    const tabNameHelper = (tab) => {
        switch (tab) {
            case 'by-players':
                return 'Bodovi svojih igrača (poslednjih 365 dana)'
            case 'by-players-current-year':
                return 'Bodovi svojih igrača (tekuća godina)'
            case 'general':
                return 'Opšta rang lista'
            case 'assembly':
                return 'Skupština'
            default:
                return 'Bodovi svojih igrača (poslednjih 365 dana)'
        }
    }

    const handlePDF = async (print = false) => {
        const filteredClubs = await handleGetClubsRankingList(
            true,
            filterParams?.hasOwnProperty('region') && filterParams?.region
                ? filterParams
                : false
        )
        setIsLoading(true)
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = activeTab === 'assembly' ? 'landscape' : 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const rankingListTitle = `Rang lista klubova`
        doc.text(rankingListTitle, marginLeft, 40)

        const title = `${tabNameHelper(activeTab)} - Ukupno: ${
            filteredClubs?.length
        }, ${formatDate(new Date())}`
        doc.text(title, marginLeft, 60)

        const rows = []

        for (let i = 1; i <= filteredClubs?.length; i++) {
            const singleClub = filteredClubs[i - 1]
            if (activeTab === 'assembly') {
                rows.push([
                    i,
                    singleClub?.club_data?.attributes?.name
                        ? singleClub?.club_data?.attributes?.name
                        : 'Nema informacije',
                    singleClub?.attributes?.registered_player_votes
                        ? singleClub?.attributes?.registered_player_votes
                        : 0,
                    singleClub?.attributes?.ranked_player_votes
                        ? singleClub?.attributes?.ranked_player_votes
                        : 0,
                    singleClub?.attributes?.court_votes
                        ? singleClub?.attributes?.court_votes
                        : 0,
                    singleClub?.attributes?.club_quality_votes
                        ? singleClub?.attributes?.club_quality_votes
                        : 0,
                    singleClub?.attributes?.international_organization_votes
                        ? singleClub?.attributes
                              ?.international_organization_votes
                        : 0,
                    singleClub?.attributes
                        ?.extra_international_organization_votes
                        ? singleClub?.attributes
                              ?.extra_international_organization_votes
                        : 0,
                    singleClub?.attributes?.international_rank_votes
                        ? singleClub?.attributes?.international_rank_votes
                        : 0,
                    singleClub?.attributes?.total_votes
                        ? singleClub?.attributes?.total_votes > 15
                            ? 15
                            : singleClub?.attributes?.total_votes
                        : 0,
                ])
            } else {
                rows.push([
                    i,
                    singleClub?.club_data?.attributes?.name
                        ? singleClub?.club_data?.attributes?.name
                        : 'Nema informacije',
                    singleClub?.attributes?.total_points
                        ? singleClub?.attributes?.total_points
                        : 0,
                    singleClub?.attributes?.total_players
                        ? singleClub?.attributes?.total_players
                        : 0,
                ])
            }
        }

        let clubsContent = {
            startY: 70,
            head:
                activeTab === 'assembly'
                    ? [
                          [
                              {
                                  content: '',
                                  colSpan: 2,
                                  styles: { halign: 'center' },
                              },
                              {
                                  content: 'Broj glasova',
                                  colSpan: 8,
                                  styles: { halign: 'center' },
                              },
                          ],
                          [
                              'Pozicija',
                              'Klub',
                              'Registrovani igrači',
                              'Rangirani igrači',
                              'Tereni',
                              'Kvalitet klubova',
                              'Međunarodna organizacija turnira',
                              'Dodatni glasovi za organizaciju međunarodnih turnira',
                              'Internacionalni rang',
                              'Ukupno',
                          ],
                      ]
                    : [['Pozicija', 'Klub', 'Broj bodova', 'Broj takmičara']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(clubsContent)

        const docFileName = `Rang-lista-klubova-${tabNameHelper(
            activeTab
        )}-${formatDate(new Date()).slice(0, -1)}.pdf`

        if (print) {
            doc.autoPrint()
            var blob = doc.output('blob')
            window.open(URL.createObjectURL(blob), '_blank')
        } else {
            doc.save(docFileName)
        }

        setIsLoading(false)
    }

    const handlePrint = async () => {
        await handlePDF(true)
    }

    // Remove filters
    const handleRemoveFilters = () => {
        setFilterParams(initialFilters)
        setShowFilters(false)
        handleGetClubsRankingList()
        setCurrentPage(1)
        setPageCount(1)
    }

    const handleFilters = async () => {
        await handleGetClubsRankingList(false, filterParams)
    }

    const setLocalStorageActiveTab = (activeTab) => {
        localStorage.setItem('clubRankingListActiveTab', activeTab)
    }

    return (
        <Row className="club-ranking-wrapper club-ranking-wrapper h-100 ">
            <Col md={12}>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    Rang liste
                                </h2>
                                <p className="d-block d-md-none">Rang liste</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to:
                                                user?.role?.type !==
                                                'competitor'
                                                    ? '/pocetna'
                                                    : '/takmicar/pocetna',
                                        }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to:
                                                user?.role?.type !==
                                                'competitor'
                                                    ? '/rang-lista-klubova'
                                                    : '/takmicar/rang-lista-klubova',
                                        }}
                                    >
                                        Rang liste
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Klubova
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        {user?.role?.type === 'admin' ? (
                            <div className="d-flex align-items-center d-md-none">
                                <Link to="/podesavanje-rangiranja-klubova">
                                    <Button type="text">
                                        <Settings />
                                    </Button>
                                </Link>
                            </div>
                        ) : null}
                    </Col>

                    {user?.role?.type !== 'competitor' && (
                        <Col
                            xs={12}
                            md={8}
                            className="d-flex justify-content-end align-items-md-center flex-column flex-md-row"
                        >
                            <Button
                                type="text"
                                leadingIcon={
                                    <DocumentTextOutlined height="24px" />
                                }
                                onClick={() => handlePDF()}
                                disabled={!clubsList?.length}
                            >
                                Eksportuj u PDF
                            </Button>
                            <Button
                                type="secondary"
                                leadingIcon={<PrintOutlined height="24px" />}
                                onClick={handlePrint}
                                disabled={!clubsList?.length}
                                className="ms-md-2 mt-2 mt-md-0"
                            >
                                Štampaj
                            </Button>
                            {/* <Button
                                type="secondary"
                                className="ms-md-2 mt-2 mt-md-0"
                                onClick={() => handleUpdateRankingList()}
                            >
                                Osveži rang liste
                            </Button> */}

                            {/* <Link
                                className="d-none d-md-block ms-md-2"
                                to="/podesavanje-rangiranja-klubova"
                                tabIndex="1"
                            >
                                <Button>Podešavanja</Button>
                            </Link> */}
                        </Col>
                    )}
                </Row>

                <Row className="pt-3 m-0">
                    <Col className="p-0 tabular-navigation">
                        <Tabs
                            defaultActiveKey="by-players"
                            id="uncontrolled-tab-example"
                            activeKey={
                                localStorage.getItem('clubRankingListActiveTab')
                                    ? localStorage.getItem(
                                          'clubRankingListActiveTab'
                                      )
                                    : 'by-players'
                            }
                            onSelect={(tab) => {
                                setActiveTab(tab)
                                setLocalStorageActiveTab(tab)
                            }}
                        >
                            <Tab
                                eventKey="by-players"
                                title="Bodovi svojih igrača (poslednjih 365 dana)"
                            >
                                {user?.role?.type !== 'competitor' ? (
                                    <Row>
                                        <Col className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                                            <Button
                                                type="secondary"
                                                onClick={(e) =>
                                                    setShowFilters(true)
                                                }
                                                disabled={!clubsList?.length}
                                                className="my-2 d-md-flex d-none"
                                            >
                                                Filtriraj
                                            </Button>
                                            <Button
                                                type="secondary"
                                                onClick={(e) =>
                                                    setShowFilters(true)
                                                }
                                                disabled={!clubsList?.length}
                                                className="my-2 d-md-none d-flex w-100"
                                            >
                                                Filtriraj
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : null}
                                <Row>
                                    <Col>
                                        <ClubsRankingTable
                                            className="d-none d-md-block"
                                            data={clubsList}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageCount={pageCount}
                                            total={total}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />

                                        <Row className="g-2 d-md-none">
                                            {clubsList.map((club, index) => (
                                                <Col xs={12} key={index}>
                                                    <ClubCard
                                                        isWhite={
                                                            index % 2 !== 0
                                                                ? true
                                                                : false
                                                        }
                                                        club={club}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab
                                eventKey="by-players-current-year"
                                title="Bodovi svojih igrača (tekuća godina)"
                            >
                                {user?.role?.type !== 'competitor' ? (
                                    <Row>
                                        <Col className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                                            <Button
                                                type="secondary"
                                                onClick={(e) =>
                                                    setShowFilters(true)
                                                }
                                                disabled={!clubsList?.length}
                                                className="my-2 d-md-flex d-none"
                                            >
                                                Filtriraj
                                            </Button>
                                            <Button
                                                type="secondary"
                                                onClick={(e) =>
                                                    setShowFilters(true)
                                                }
                                                disabled={!clubsList?.length}
                                                className="my-2 d-md-none d-flex w-100"
                                            >
                                                Filtriraj
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : null}
                                <Row>
                                    <Col>
                                        <ClubsRankingTable
                                            className="d-none d-md-block"
                                            data={clubsList}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageCount={pageCount}
                                            total={total}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />

                                        <Row className="g-2 d-md-none">
                                            {clubsList.map((club, index) => (
                                                <Col xs={12} key={index}>
                                                    <ClubCard
                                                        isWhite={
                                                            index % 2 !== 0
                                                                ? true
                                                                : false
                                                        }
                                                        club={club}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                            {/* Commented for now until its finished */}
                            {/* <Tab eventKey="general" title="Opšta rang lista">
                                <Row>
                                    <Col>
                                        <ClubsRankingTable
                                            className="d-none d-md-block"
                                            data={clubsList}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageCount={pageCount}
                                            total={total}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />

                                        <Row className="g-2 d-md-none">
                                            {clubsList.map((club, index) => (
                                                <Col xs={12} key={index}>
                                                    <ClubCard
                                                        isWhite={
                                                            index % 2 !== 0
                                                                ? true
                                                                : false
                                                        }
                                                        club={club}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab> */}
                            <Tab eventKey="assembly" title="Skupština">
                                {user?.role?.type !== 'competitor' ? (
                                    <Row>
                                        <Col className="d-flex flex-column flex-md-row align-items-center justify-content-end">
                                            <Button
                                                type="text"
                                                leadingIcon={
                                                    <DocumentTextOutlined height="24px" />
                                                }
                                                onClick={async (e) =>
                                                    exportCSV(
                                                        await handleGetClubsRankingList(
                                                            true
                                                        ),
                                                        activeTab,
                                                        setIsLoading
                                                    )
                                                }
                                                disabled={!clubsList?.length}
                                                className="my-2 d-md-flex d-none"
                                            >
                                                Export u CSV
                                            </Button>
                                            <Button
                                                type="secondary"
                                                onClick={(e) =>
                                                    setShowFilters(true)
                                                }
                                                disabled={!clubsList?.length}
                                                className="ms-2 my-2 d-md-flex d-none"
                                            >
                                                Filtriraj
                                            </Button>
                                            {user?.role?.type === 'admin' && (
                                                <Link
                                                    className="ms-2 my-2 d-none d-md-block"
                                                    to="/podesavanje-rangiranja-klubova"
                                                    tabIndex="1"
                                                >
                                                    <Button>Podešavanja</Button>
                                                </Link>
                                            )}
                                            <Button
                                                type="text"
                                                leadingIcon={
                                                    <DocumentTextOutlined height="24px" />
                                                }
                                                onClick={async (e) =>
                                                    exportCSV(
                                                        await handleGetClubsRankingList(
                                                            true
                                                        ),
                                                        activeTab,
                                                        setIsLoading
                                                    )
                                                }
                                                disabled={!clubsList?.length}
                                                className="mt-2 d-md-none d-flex w-100"
                                            >
                                                Export u CSV
                                            </Button>
                                            <Button
                                                type="secondary"
                                                onClick={(e) =>
                                                    setShowFilters(true)
                                                }
                                                disabled={!clubsList?.length}
                                                className="mt-2 d-md-none d-flex w-100"
                                            >
                                                Filtriraj
                                            </Button>
                                            {user?.role?.type === 'admin' && (
                                                <Link
                                                    className="d-md-none d-block my-2 w-100"
                                                    to="/podesavanje-rangiranja-klubova"
                                                    tabIndex="1"
                                                >
                                                    <Button className="w-100">
                                                        Podešavanja
                                                    </Button>
                                                </Link>
                                            )}
                                        </Col>
                                    </Row>
                                ) : null}
                                <Row>
                                    <Col>
                                        <ClubsAssemblyRankingTable
                                            className="d-none d-md-block"
                                            data={clubsList}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageCount={pageCount}
                                            total={total}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                            setRefreshData={setRefreshData}
                                        />

                                        <Row className="g-2 d-md-none">
                                            {clubsList.map((club, index) => (
                                                <Col xs={12} key={index}>
                                                    <ClubAssemblyCard
                                                        isWhite={
                                                            index % 2 !== 0
                                                                ? true
                                                                : false
                                                        }
                                                        club={club}
                                                        setRefreshData={
                                                            setRefreshData
                                                        }
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>

            <Offcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col xs={12}>
                            <MultiSelectSearch
                                label={'Region'}
                                animated
                                options={
                                    regions
                                        ? regions
                                              .filter(
                                                  (region) => region !== 'TSS'
                                              )
                                              .reduce(
                                                  (accumulator, region) => [
                                                      ...accumulator,
                                                      {
                                                          id: region,
                                                          value: region,
                                                      },
                                                  ],
                                                  []
                                              )
                                        : []
                                }
                                noOptionsMessage={'Nema rezultata'}
                                onChange={(e) => {
                                    e?.length === 0
                                        ? setFilterParams({
                                              ...filterParams,
                                              region: '',
                                          })
                                        : setFilterParams({
                                              ...filterParams,
                                              region: e,
                                          })
                                }}
                                isMulti={true}
                                className="mb-3"
                                value={filterParams?.region}
                                placeholder="Region"
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <Button
                                className="w-100"
                                onClick={(e) => handleFilters()}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="text"
                                className="w-100"
                                onClick={() => handleRemoveFilters()}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            <UpdateRankingListModal
                onHide={() => setShowUpdateRankingListModal(false)}
                show={showUpdateRankingListModal}
                modalText={'Rang liste uspešno osvežene.'}
            />
        </Row>
    )
}

export default ClubsRanking
