import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getTournamentReport } from '../../lib/api'
import { formatDate, formatImgUrl, pillHandler } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import { useAppContext } from '../../lib/contextLib'

const CompetitionTableRow = ({ result, finished }) => {
    const { user } = useAppContext()
    let navigate = useNavigate()
    const [hasReport, setHasReport] = useState(false)

    const hasTournamentReport = async (tournamentUuid) => {
        try {
            let tournamentReportResponse = await getTournamentReport(
                tournamentUuid
            )

            if (
                tournamentReportResponse &&
                tournamentReportResponse?.length > 0
            ) {
                setHasReport(tournamentReportResponse[0])
            } else {
                setHasReport(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleEdit = () => {
        navigate(`${result?.attributes?.uuid}/izmeni-takmicenje`)
    }

    const setLocalStorageActiveTab = (activeTab) => {
        localStorage.setItem('competitionActiveTab', activeTab)
    }

    useEffect(() => {
        hasTournamentReport(result?.attributes?.uuid)
    }, [result])

    return (
        <tr className="setting-table-row">
            <td className="align-middle table-data-min-width">
                <Link to={`/takmicenja/${result?.attributes?.uuid}`}>
                    <p className="link">{result?.attributes?.name}</p>
                </Link>
            </td>
            <td className="align-middle d-flex align-items-center table-data-min-width">
                {result?.attributes?.club_organizer?.attributes?.name ? (
                    <>
                        <img
                            src={
                                result?.attributes?.club_organizer?.attributes
                                    ?.logo?.data
                                    ? formatImgUrl(
                                          result?.attributes?.club_organizer
                                              ?.attributes?.logo?.data
                                              ?.attributes?.url
                                      )
                                    : generateAvatar(
                                          result?.attributes?.club_organizer
                                              ?.attributes?.name
                                      )
                            }
                            alt={`Logo kluba`}
                            width={'24px'}
                            height={'24px'}
                            style={{
                                maxHeight: '24px',
                                maxWidth: '24px',
                                borderRadius: '50%',
                            }}
                        />
                        <Link
                            to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                        >
                            <p className="ms-2 link">
                                {
                                    result?.attributes?.club_organizer
                                        ?.attributes?.name
                                }
                            </p>
                        </Link>
                    </>
                ) : (
                    <p>-</p>
                )}
            </td>
            <td className="align-middle table-dates-min-width">
                <p>
                    {result?.attributes?.start_date
                        ? formatDate(result?.attributes?.start_date)
                        : '-'}
                </p>
            </td>
            <td className="align-middle table-dates-min-width">
                <p>
                    {result?.attributes?.application_deadline_date
                        ? formatDate(
                              result?.attributes?.application_deadline_date
                          )
                        : '-'}
                </p>
            </td>
            <td className="align-middle table-dates-min-width">
                <p>
                    {result?.attributes?.checkout_deadline_date
                        ? formatDate(result?.attributes?.checkout_deadline_date)
                        : '-'}
                </p>
            </td>
            <td className="align-middle" style={{ minWidth: '103px' }}>
                <p>
                    {result?.attributes?.tournament_category
                        ?.tournament_category_name
                        ? result?.attributes?.tournament_category
                              ?.tournament_category_name
                        : '-'}
                </p>
            </td>
            {!finished && user?.role?.type !== 'competitor' && (
                <td className="align-middle link" onClick={handleEdit}>
                    Izmeni
                </td>
            )}
            {finished && (
                <>
                    <td style={{ minWidth: '170px' }}>
                        {pillHandler(hasReport?.attributes?.is_verified)}
                    </td>
                    {user?.role?.type !== 'competitor' && (
                        <td>
                            {hasReport?.attributes?.is_verified === false && (
                                <Link
                                    to={`/takmicenja/${result?.attributes?.uuid}`}
                                    onClick={() =>
                                        setLocalStorageActiveTab('izvestaj')
                                    }
                                >
                                    <p className="link">Verifikuj</p>
                                </Link>
                            )}
                        </td>
                    )}
                </>
            )}
        </tr>
    )
}

export default CompetitionTableRow
