import React, { useState } from 'react'

import { Fragment } from 'react'
import Button from '../../../Button'
import Trash from '../../../../assets/svg/Trash'
import ConfirmationModal from '../../../ConfirmationModal'
import { deleteTournamentField } from '../../../../lib/api'

const RemoveFieldModal = ({ field, handleShowTournamentsField }) => {
    const [show, setShow] = useState(false)

    const handleDeleteField = async () => {
        try {
            const deletedField = await deleteTournamentField(
                field?.attributes?.uuid
            )

            if (!deletedField) {
                throw new Error('Error deleting field')
            }

            setShow(false)
            handleShowTournamentsField()
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Fragment>
            <Button
                type="text"
                leadingIcon={<Trash />}
                className="p-2"
                onClick={() => setShow(true)}
            ></Button>

            <ConfirmationModal
                show={show}
                onHide={() => setShow(false)}
                modalTitle="Potvrda brisanja"
                modalText="Da li ste sigurni da želite da obrišete teren? Brisanjem terena se brišu i svi mečevi u rasporedu za ovaj teren."
                onClick={handleDeleteField}
            />
        </Fragment>
    )
}

export default RemoveFieldModal
