import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import turniriPNG from '../../../assets/images/turniri.png'
import OutlinedFilter from '../../../assets/svg/OutlinedFilter'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import SearchInput from '../../../components/SearchInput'
import TableRefereeCompetitionsPagination from '../../../components/TableRefereeCompetitionsPagination'
import {
    getCodebook,
    getSingleClub,
    getTournamentReport,
    getTournaments,
} from '../../../lib/api'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import AsyncSelect from '../../../components/AsyncSelect'
import NoDataForView from '../../../components/NoDataForView'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import SingleTournamentRow from '../../../components/TableRefereeCompetitionsPagination/SingleTournamentRow'
import { useAppContext } from '../../../lib/contextLib'
import {
    debounce,
    formatDate,
    getClubList,
    isArrayEmpty,
} from '../../../lib/helpers'
import { font } from '../../Admin/Competitions/CompetitionProfile/font'
import RadioButton from '../../../components/RadioButton'
import './styles.scss'

const searchInputID = `searchInput-competitions`

function RefereeCompetitions() {
    const { setIsLoading, user } = useAppContext()
    const location = useLocation()
    const { type } = location?.state ? location?.state : false
    let isCompetitionType = type
        ? type
        : location?.pathname === '/sudije/individualna-takmicenja'
        ? 'Individualno'
        : location?.pathname === '/sudije/ekipna-takmicenja'
        ? 'Ekipno'
        : ''

    //* Codebooks
    const [regions, setRegions] = useState([])
    const [tournamentCategories, setTournamentCategories] = useState([])
    const [tournamentSubCategories, setTournamentSubCategories] = useState([])
    const [tournamentOrganisationTypes, setTournamentOrganisationTypes] =
        useState([])
    const [genders, setGenders] = useState([])
    const [playersCompetition, setPlayersCompetition] = useState([])
    const competitorCompetitions = [
        '10s - Crveni',
        '10s - Narandžasti',
        '10s - Zeleni',
    ]

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
        await getCodebook('codebooks.region', setRegions)
        await getCodebook(
            'codebooks.tournament-organisation-type',
            setTournamentOrganisationTypes
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentCategories
        )
        await getCodebook(
            'codebooks.tournament-category',
            setTournamentSubCategories,
            true
        )
        await getCodebook('codebooks.players-competition').then((result) => {
            setPlayersCompetition(
                result.reduce((accumulator, currentValue) => {
                    if (!['10', '45'].includes(currentValue)) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
    }

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [selectedTab, setSelectedTab] = useState('nadolazeca')
    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    // Filtering
    const [showFilters, setShowFilters] = useState(false)

    const initialFilters = {
        region: '',
        category: '',
        sub_category: '',
        club_organizer_uuid: '',
        tournament_type: isCompetitionType,
    }

    const [filterParams, setFilterParams] = useState(initialFilters)
    const [selectedClub, setSelectedClub] = useState(null)

    const getComingCompetitions = async (
        page,
        presetFilters,
        allEntries = false
    ) => {
        if (!user) return
        let search = document.getElementById(searchInputID)
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getTournaments(
            null,
            search && search?.value ? { name: search?.value } : null,
            presetFilters
                ? presetFilters === 1
                    ? null
                    : presetFilters
                : {
                      ...filterParams,
                      tournament_type: isCompetitionType,
                      refereeUuid: user?.data?.attributes?.uuid,
                  },
            page,
            false,
            allEntries,
            rowsPerPage
        )

        if (tournaments) {
            const tournamentsPromises = tournaments.map(async (tournament) => {
                const club_organizer = await getSingleClub(
                    tournament.attributes.club_organizer_uuid
                )
                return {
                    ...tournament,
                    attributes: {
                        ...tournament.attributes,
                        club_organizer,
                    },
                }
            })

            const updatedTournaments = await Promise.all(tournamentsPromises)
            if (allEntries) {
                return updatedTournaments
            } else {
                setComingCompetitions(updatedTournaments)
                setPageCount(pgCount)
                setTotal(totalCount)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
    }

    const getAllFinishedCompetitions = async (
        page,
        presetFilters,
        allEntries = false
    ) => {
        if (!user) return
        let search = document.getElementById(searchInputID)
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getTournaments(
            null,
            search && search?.value ? { name: search?.value } : null,
            presetFilters
                ? presetFilters === 1
                    ? null
                    : presetFilters
                : {
                      ...filterParams,
                      tournament_type: isCompetitionType,
                      refereeUuid: user?.data?.attributes?.uuid,
                  },
            page,
            true,
            allEntries,
            rowsPerPage
        )

        if (tournaments) {
            const tournamentsPromises = tournaments.map(async (tournament) => {
                const club_organizer = await getSingleClub(
                    tournament.attributes.club_organizer_uuid
                )
                return {
                    ...tournament,
                    attributes: {
                        ...tournament.attributes,
                        club_organizer,
                    },
                }
            })

            const updatedTournaments = await Promise.all(tournamentsPromises)
            if (allEntries) {
                return updatedTournaments
            } else {
                setFinishedCompetitions(updatedTournaments)
                setPageCount(pgCount)
                setTotal(totalCount)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }
    }

    const handleClubSelect = (e) => {
        const { uuid, value } = e

        setSelectedClub({ uuid: uuid, value: value })
        setFilterParams({ ...filterParams, club_organizer_uuid: uuid })
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage)
            : getAllFinishedCompetitions(currentPage)
        handleCodebooks()
    }, [currentPage, selectedTab, isCompetitionType, type, rowsPerPage])

    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        selectedTab === 'nadolazeca'
            ? await getComingCompetitions(1)
            : await getAllFinishedCompetitions(1)
    }, 1000)

    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        setSelectedClub(null)
        setShowFilters(false)
        document.getElementById(searchInputID).value = ''
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(1, {
                  ...initialFilters,
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
            : getAllFinishedCompetitions(1, {
                  ...initialFilters,
                  tournament_type: isCompetitionType,
                  refereeUuid: user?.data?.attributes?.uuid,
              })
    }

    // Print PDF coming and finished competitions

    const handlePdfExportComing = async () => {
        setIsLoading(true)
        const filteredTournaments = await getComingCompetitions(
            1,
            {
                ...filterParams,
                tournament_type: isCompetitionType,
                refereeUuid: user?.data?.attributes?.uuid,
            },
            true
        )
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = 'Nadolazeća takmičenja'
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredTournaments?.length; i++) {
            const compt = filteredTournaments[i - 1]
            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',

                compt?.attributes?.start_date
                    ? formatDate(compt?.attributes?.start_date)
                    : '-',
                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : '-',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
            ])
        }

        let comingCompetitionsContent = {
            startY: 50,
            head: [
                [
                    'Naziv',
                    'Organizator/Domaćin',
                    'Početak',
                    'Prijava do',
                    'Odjava do',
                    'Kategorija',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(comingCompetitionsContent)
        doc.save('Nadolazeća takmičenja.pdf')
        setShowFilters(false)
        setIsLoading(false)
    }

    const handlePdfExportFinished = async () => {
        setIsLoading(true)
        const filteredTournaments = await getAllFinishedCompetitions(
            1,
            {
                ...filterParams,
                tournament_type: isCompetitionType,
                refereeUuid: user?.data?.attributes?.uuid,
            },
            true
        )

        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'landscape' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Završena takmičenja, Ukupno: ${filteredTournaments?.length}`
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredTournaments?.length; i++) {
            const compt = filteredTournaments[i - 1]
            let status = false

            try {
                let tournamentReportResponse = await getTournamentReport(
                    compt?.attributes?.uuid
                )

                if (
                    tournamentReportResponse &&
                    tournamentReportResponse?.length > 0
                ) {
                    if (tournamentReportResponse[0]?.attributes?.is_verified) {
                        status = true
                    } else {
                        status = false
                    }
                } else {
                    status = false
                }
            } catch (error) {
                console.log(error)
            }

            rows.push([
                compt?.attributes?.name,
                compt?.attributes?.club_organizer?.attributes?.name
                    ? compt?.attributes?.club_organizer?.attributes?.name
                    : '-',

                compt?.attributes?.start_date
                    ? formatDate(compt?.attributes?.start_date)
                    : '-',
                compt?.attributes?.application_deadline_date
                    ? formatDate(compt?.attributes?.application_deadline_date)
                    : '-',
                compt?.attributes?.checkout_deadline_date
                    ? formatDate(compt?.attributes?.checkout_deadline_date)
                    : '-',
                compt?.attributes?.tournament_category?.tournament_category_name
                    ? compt?.attributes?.tournament_category
                          ?.tournament_category_name
                    : '-',
                status ? 'Verifikovan' : 'Nije verifikovan',
            ])
        }
        let finishedCompetitionsContent = {
            startY: 50,
            head: [
                [
                    'Naziv',
                    'Organizator/Domaćin',
                    'Početak',
                    'Prijava do',
                    'Odjava do',
                    'Kategorija',
                    'Status',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(finishedCompetitionsContent)
        doc.save('Završena takmičenja.pdf')
        setShowFilters(false)
        setIsLoading(false)
    }

    const handleTabSelect = (tab) => {
        setSelectedTab((_) => {
            tab === 'nadolazeca'
                ? getComingCompetitions(1)
                : getAllFinishedCompetitions(1)
            return tab
        })
    }

    return (
        <Row className="referee-competitions-ranking-wrapper">
            {comingCompetitions || finishedCompetitions ? (
                <>
                    <Col xs={12}>
                        <Row className="pt-3">
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex align-items-center justify-content-between"
                            >
                                <Row>
                                    <Col className="">
                                        <h2 className="d-none d-md-block">
                                            Takmičenja
                                        </h2>
                                        <p className="d-block d-md-none">
                                            Takmičenja
                                        </p>
                                        <Breadcrumb className="mt-md-2 mt-1">
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{
                                                    to: '/sudije/pocetna',
                                                }}
                                            >
                                                Početna
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {type
                                                    ? type === 'Ekipno'
                                                        ? 'Ekipna'
                                                        : type ===
                                                          'Individualno'
                                                        ? 'Individualna'
                                                        : 'Iz kalendara TSS'
                                                    : 'Iz kalendara TSS'}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center d-md-none">
                                    <Button
                                        type="text"
                                        onClick={() => setShowFilters(true)}
                                    >
                                        <OutlinedFilter />
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="offset-md-2">
                                <Row className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                        <SearchInput
                                            id={searchInputID}
                                            placeholder="Pronađi takmičenje"
                                            onChange={(e) =>
                                                updateDebounceText(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Button
                                            type="secondary"
                                            className="ms-2 d-none d-md-block"
                                            onClick={() => setShowFilters(true)}
                                        >
                                            Filtriraj
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row className="pt-3 m-0">
                            <Col className="p-0 tabular-navigation">
                                <Tabs
                                    id="uncontrolled-tab-example"
                                    onSelect={(tab) => {
                                        setCurrentPage(1)
                                        document.getElementById(
                                            searchInputID
                                        ).value = ''
                                        handleDisableFiltering()
                                        handleTabSelect(tab)
                                    }}
                                >
                                    <Tab
                                        eventKey="nadolazeca"
                                        title="Nadolazeća"
                                    >
                                        <div className="d-none d-md-block">
                                            <TableRefereeCompetitionsPagination
                                                data={comingCompetitions}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageCount={pageCount}
                                                total={total}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        </div>
                                        <div className="d-block d-md-none">
                                            {!isArrayEmpty(
                                                comingCompetitions
                                            ) ? (
                                                comingCompetitions.map(
                                                    (result, index) => (
                                                        <SingleTournamentRow
                                                            key={index}
                                                            result={result}
                                                            isWhite={
                                                                index % 2 !== 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            mobile
                                                        />
                                                    )
                                                )
                                            ) : (
                                                <NoDataForView
                                                    title={`Nema nadolazećih takmičenja`}
                                                    description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                                    className="border-0"
                                                    column
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="zavrsena" title="Završena">
                                        <div className="d-none d-md-block">
                                            <TableRefereeCompetitionsPagination
                                                data={finishedCompetitions}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageCount={pageCount}
                                                total={total}
                                                finished={true}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        </div>
                                        <div className="d-block d-md-none">
                                            {!isArrayEmpty(
                                                finishedCompetitions
                                            ) ? (
                                                finishedCompetitions.map(
                                                    (result, index) => (
                                                        <SingleTournamentRow
                                                            key={index}
                                                            result={result}
                                                            isWhite={
                                                                index % 2 !== 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            mobile
                                                            finished
                                                        />
                                                    )
                                                )
                                            ) : (
                                                <NoDataForView
                                                    title={`Nema završenih takmičenja`}
                                                    description={`Ovde je prikazana lista završenih takmičenja.`}
                                                    className="border-0"
                                                    column
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                </>
            ) : (
                <Col
                    xs={12}
                    className="d-flex flex-column align-items-center justify-content-center pt-4"
                >
                    <img className="no-data-img" src={turniriPNG}></img>
                    <h2 className="mt-5">Takmičenja</h2>
                    <p className="mt-2">Nema takmičenja u kojima učestvujete</p>
                </Col>
            )}
            <Offcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col md={12} className="mt-3">
                            <RadioButton
                                name="Pol"
                                radioValues={genders}
                                onClick={(e) => {
                                    filterParams?.gender === e.target.value
                                        ? setFilterParams({
                                              ...filterParams,
                                              gender: '',
                                          })
                                        : null
                                }}
                                onChange={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        gender: e.target.value,
                                    })
                                }}
                                checkedValue={filterParams?.gender || ''}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <AsyncSelect
                                loadOptions={getClubList}
                                onChange={handleClubSelect}
                                label={'Izaberi klub'}
                                value={selectedClub}
                                placeholder={'Pretraži i izaberi klub...'}
                                noOptionsMessage={'Traženi klub ne postoji'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Region'}
                                value={filterParams?.region || ''}
                                placeholder="Region"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        region: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={
                                    regions
                                        ? regions
                                              .filter(
                                                  (region) => region !== 'TSS'
                                              )
                                              .reduce(
                                                  (accumulator, region) => [
                                                      ...accumulator,
                                                      region,
                                                  ],
                                                  filterParams?.region
                                                      ? ['Odselektuj']
                                                      : []
                                              )
                                        : []
                                }
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Konkurencija'}
                                className="yearDropdown"
                                value={filterParams?.competition || ''}
                                placeholder="Konkurencija"
                                handleSelect={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        competition:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }}
                                dropdownLinks={playersCompetition?.reduce(
                                    (accumulator, currentValue) => [
                                        ...accumulator,
                                        currentValue,
                                    ],
                                    filterParams?.competition
                                        ? [
                                              'Odselektuj',
                                              ...competitorCompetitions,
                                          ]
                                        : competitorCompetitions
                                )}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Kategorija'}
                                className="category-dropdown"
                                value={filterParams?.category || ''}
                                placeholder="Kategorija"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        category: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={tournamentCategories?.reduce(
                                    (accumulator, category) => [
                                        ...accumulator,
                                        category,
                                    ],
                                    filterParams?.category ? ['Odselektuj'] : []
                                )}
                            />
                        </Col>

                        {filterParams?.category === 'III' && (
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label={'Potkategorija'}
                                    value={filterParams?.sub_category || ''}
                                    placeholder="Potkategorija"
                                    handleSelect={(e) =>
                                        setFilterParams({
                                            ...filterParams,
                                            sub_category:
                                                e === 'Odselektuj' ? '' : e,
                                        })
                                    }
                                    dropdownLinks={tournamentSubCategories?.reduce(
                                        (accumulator, subcategory) => [
                                            ...accumulator,
                                            subcategory,
                                        ],
                                        filterParams?.sub_category
                                            ? ['Odselektuj']
                                            : []
                                    )}
                                />
                            </Col>
                        )}

                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Tip organizovanja takmičenja'}
                                placeholder="Izaberi tip organizovanja..."
                                value={
                                    filterParams?.tournament_organisation_type ||
                                    ''
                                }
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        tournament_organisation_type:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={tournamentOrganisationTypes?.reduce(
                                    (accumulator, category) => [
                                        ...accumulator,
                                        category,
                                    ],
                                    filterParams?.tournament_organisation_type
                                        ? ['Odselektuj']
                                        : []
                                )}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <Button
                                className="w-100"
                                onClick={() => {
                                    selectedTab === 'nadolazeca'
                                        ? getComingCompetitions(1)
                                        : getAllFinishedCompetitions(1)
                                }}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="secondary"
                                className="w-100"
                                onClick={
                                    selectedTab === 'nadolazeca'
                                        ? handlePdfExportComing
                                        : handlePdfExportFinished
                                }
                            >
                                Štampaj rezultate
                            </Button>
                            <Button
                                type="text"
                                className="w-100 mt-3"
                                onClick={() => handleDisableFiltering()}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Row>
    )
}

export default RefereeCompetitions
