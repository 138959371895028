import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { updateClubAssemblyRankingPoints } from '../../../lib/api'
import { formatImgUrl, notLeadingZero, numbersOnly } from '../../../lib/helpers'
import { useDebounce } from '../../../lib/hooks/useDebounce'
import { generateAvatar } from '../../../utils/generateAvatar'
import TextField from '../../TextField'
import { useAppContext } from '../../../lib/contextLib'

const ClubAssemblyRankingTableRow = ({ club, position, setRefreshData }) => {
    const { user } = useAppContext()
    const [mounted, setMounted] = useState(false)
    const [inputValue, setInputValue] = useState()
    const [inputValueOrganization, setInputValueOrganization] = useState()
    const debouncedInputValue = useDebounce(inputValue)
    const debouncedInputValueOrganization = useDebounce(inputValueOrganization)

    const handleInputChange = async (e) => {
        const { id, value } = e.target

        const response = await updateClubAssemblyRankingPoints(
            club?.attributes?.uuid,
            {
                [id]: +value,
            }
        )

        let zeroValue = !value ? 0 : value

        let inputValueChanged = zeroValue !== club?.attributes?.[id]

        if (response) {
            setInputValue(null)
            setInputValueOrganization(null)
            let activeElement = document.activeElement
            inputValueChanged && activeElement.blur()
            setRefreshData(true)
        }
    }

    const handlerOnBlur = (e) => {
        if (e.target.value === '') {
            e.target.value = 0
        }
    }

    useEffect(() => {
        if (mounted) {
            if (debouncedInputValue === null) {
                return
            }
            handleInputChange(debouncedInputValue)
        } else {
            setMounted(true)
        }
    }, [debouncedInputValue])

    useEffect(() => {
        if (mounted) {
            if (debouncedInputValueOrganization === null) {
                return
            }
            handleInputChange(debouncedInputValueOrganization)
        } else {
            setMounted(true)
        }
    }, [debouncedInputValueOrganization])

    return (
        <tr>
            <td className="link-align-end" style={{ minWidth: '240px' }}>
                <div className="d-flex align-items-center justify-content-start">
                    <p className="me-5">{position}.</p>
                    <img
                        src={
                            club?.club_data?.attributes?.logo?.data
                                ? formatImgUrl(
                                      club?.club_data?.attributes?.logo?.data
                                          ?.attributes?.formats?.thumbnail?.url
                                  )
                                : generateAvatar(
                                      club?.club_data?.attributes?.name
                                  )
                        }
                        className="me-2"
                        alt={`Logo kluba`}
                        width={'24px'}
                        height={'24px'}
                    />
                    <Link to={`/klubovi/${club?.club_data?.attributes?.uuid}`}>
                        <p className="link">
                            {club?.club_data?.attributes?.name}
                        </p>
                    </Link>
                </div>
            </td>
            <td style={{ minWidth: '100px' }}>
                <p>{club?.attributes?.registered_player_votes || 0}</p>
            </td>
            <td style={{ minWidth: '80px' }}>
                <p>{club?.attributes?.ranked_player_votes || 0}</p>
            </td>
            <td style={{ minWidth: '76px' }}>
                <p>{club?.attributes?.court_votes || 0}</p>
            </td>
            <td style={{ minWidth: '100px' }}>
                <p>{club?.attributes?.club_quality_votes || 0}</p>
            </td>
            <td style={{ minWidth: '134px' }}>
                <p>{club?.attributes?.international_organization_votes || 0}</p>
            </td>
            <td style={{ minWidth: '120px' }}>
                {user?.role?.type !== 'competitor' ? (
                    <TextField
                        className="table-points-input-field"
                        id="extra_international_organization_votes"
                        name="extra_international_organization_votes"
                        type="tel"
                        onInput={(e) => {
                            numbersOnly(e)
                            notLeadingZero(e)
                            setInputValueOrganization(e)
                        }}
                        onBlur={handlerOnBlur}
                        value={
                            inputValueOrganization
                                ? inputValueOrganization?.target?.value
                                : club?.attributes
                                      ?.extra_international_organization_votes
                                ? club?.attributes
                                      ?.extra_international_organization_votes
                                : 0
                        }
                    />
                ) : (
                    <p>
                        {inputValueOrganization
                            ? inputValueOrganization?.target?.value
                            : club?.attributes
                                  ?.extra_international_organization_votes
                            ? club?.attributes
                                  ?.extra_international_organization_votes
                            : 0}
                    </p>
                )}
            </td>
            <td style={{ minWidth: '134px' }}>
                {user?.role?.type !== 'competitor' ? (
                    <TextField
                        className="table-points-input-field"
                        id="international_rank_votes"
                        name="international_rank_votes"
                        type="tel"
                        onInput={(e) => {
                            numbersOnly(e)
                            notLeadingZero(e)
                            setInputValue(e)
                        }}
                        onBlur={handlerOnBlur}
                        value={
                            inputValue
                                ? inputValue?.target?.value
                                : club?.attributes?.international_rank_votes
                                ? club?.attributes?.international_rank_votes
                                : 0
                        }
                    />
                ) : (
                    <p>
                        {inputValue
                            ? inputValue?.target?.value
                            : club?.attributes?.international_rank_votes
                            ? club?.attributes?.international_rank_votes
                            : 0}
                    </p>
                )}
            </td>
            <td style={{ minWidth: '56px', maxWidth: '90px' }}>
                <p>
                    {club?.attributes?.total_votes
                        ? club?.attributes?.total_votes > 15
                            ? 15
                            : club?.attributes?.total_votes
                        : 0}
                </p>
            </td>
        </tr>
    )
}

export default ClubAssemblyRankingTableRow
