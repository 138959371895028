import React, { useState, useEffect } from 'react'

import { Row, Col, Offcanvas } from 'react-bootstrap'
import Checkbox from '../../Checkbox'
import MultiSelectSearch from '../../MultiSelectSearch'
import TextField from '../../TextField'
import Button from '../../Button'
import { getCodebook } from '../../../lib/api'
import {
    numbersOnly,
    formatDate,
    handleShowContactPerson,
    checkClubMemberships,
} from '../../../lib/helpers'
import { useAppContext } from '../../../lib/contextLib'
import { font } from '../../../containers/Admin/Competitions/CompetitionProfile/font'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const FiltersCanvas = ({
    open,
    onClose,
    filterParams,
    setFilterParams,
    removeFilters,
    getAllClubs,
}) => {
    const { setIsLoading } = useAppContext()
    const [regions, setRegions] = useState([])

    const handleCodebook = async () => {
        await getCodebook('codebooks.region', setRegions)
    }

    const handlePdfExport = async () => {
        const filteredClubs = await getAllClubs(1, filterParams, true)
        setIsLoading(true)
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(12)

        const title = `Klubovi - Ukupno: ${filteredClubs?.length}, ${formatDate(
            new Date()
        ).slice(0, -1)}.`
        doc.text(title, marginLeft, 40)
        const rows = []
        for (let i = 1; i <= filteredClubs?.length; i++) {
            const c = filteredClubs[i - 1]
            const clubStatusResponse = await checkClubMemberships(
                c?.attributes?.uuid
            )

            rows.push([
                c?.attributes?.name || '-',
                c?.attributes?.short_name || '-',
                c?.attributes?.region?.region_name || '-',
                c?.attributes?.email || '-',
                handleShowContactPerson(c?.attributes?.staff),
                c?.attributes?.phone || '-',
                clubStatusResponse || '-',
            ])
        }
        let clubsContent = {
            startY: 50,
            head: [
                [
                    'Klub',
                    'Skraćeno ime',
                    'Region',
                    'Email',
                    'Kontakt osoba',
                    'Telefon',
                    'Status',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }
        doc.autoTable(clubsContent)
        doc.save(
            `Klubovi - Ukupno: ${filteredClubs?.length}, ${formatDate(
                new Date()
            ).slice(0, -1)}.pdf`
        )
        onClose()
        setIsLoading(false)
    }

    const handleCSVExport = async () => {
        try {
            await exportCSV(filterParams, getAllClubs, setIsLoading)
            setShowFilters(false)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        handleCodebook()
    }, [])

    return (
        <Offcanvas show={open} onHide={onClose} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <h3>Filtriraj</h3>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <hr className="p-0 mt-2 mb-2" />
            <Offcanvas.Body>
                <Row>
                    <Col xs={12}>
                        <div className="mb-3">
                            <h5 className="mb-2 me-1">Status</h5>
                            <Checkbox
                                id="active"
                                onChange={() => {
                                    if (filterParams?.is_active) {
                                        let { is_active, ...rest } =
                                            filterParams
                                        setFilterParams(rest)
                                    } else {
                                        setFilterParams((state) => ({
                                            ...state,
                                            is_active: true,
                                        }))
                                    }
                                }}
                                checked={filterParams?.is_active || ''}
                                label="Aktivni"
                            />
                            <Checkbox
                                id="inactive"
                                onChange={() => {
                                    if (filterParams?.is_inactive) {
                                        let { is_inactive, ...rest } =
                                            filterParams
                                        setFilterParams(rest)
                                    } else {
                                        setFilterParams((state) => ({
                                            ...state,
                                            is_inactive: true,
                                        }))
                                    }
                                }}
                                checked={filterParams?.is_inactive || ''}
                                label="Neaktivni"
                            />
                            <Checkbox
                                id="in_rest"
                                onChange={() => {
                                    if (filterParams?.is_resting) {
                                        let { is_resting, ...rest } =
                                            filterParams
                                        setFilterParams(rest)
                                    } else {
                                        setFilterParams((state) => ({
                                            ...state,
                                            is_resting: true,
                                        }))
                                    }
                                }}
                                checked={filterParams?.is_resting || ''}
                                label="U mirovanju"
                            />
                        </div>
                        <MultiSelectSearch
                            label={'Region'}
                            animated
                            options={
                                regions
                                    ? regions
                                          .filter((region) => region !== 'TSS')
                                          .reduce(
                                              (accumulator, region) => [
                                                  ...accumulator,
                                                  {
                                                      id: region,
                                                      value: region,
                                                  },
                                              ],
                                              []
                                          )
                                    : []
                            }
                            noOptionsMessage={'Nema rezultata'}
                            onChange={(e) => {
                                e?.length === 0
                                    ? setFilterParams({
                                          ...filterParams,
                                          region: '',
                                      })
                                    : setFilterParams({
                                          ...filterParams,
                                          region: e,
                                      })
                            }}
                            isMulti={true}
                            className="mb-3"
                            value={filterParams?.region}
                            placeholder="Region"
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj igrača (od)"
                            placeholder="Broj igrača (od)"
                            value={filterParams?.number_of_players_from || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_players_from: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj igrača (do)"
                            placeholder="Broj igrača (do)"
                            value={filterParams?.number_of_players_to || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_players_to: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj trenera (od)"
                            placeholder="Unesi broj trenera (od)"
                            value={filterParams?.number_of_coaches_from || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_coaches_from: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj trenera (do)"
                            placeholder="Unesi broj trenera (do)"
                            value={filterParams?.number_of_coaches_to || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_coaches_to: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj šljaka terena (od)"
                            placeholder="Broj šljake terena (od)"
                            value={filterParams?.number_of_clay_courts || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_clay_courts: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj hard terena (od)"
                            placeholder="Broj hard terena (od)"
                            value={filterParams?.number_of_hard_courts || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_hard_courts: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj tepih terena (od)"
                            placeholder="Broj tepih terena (od)"
                            value={filterParams?.number_of_carpet_courts || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_carpet_courts: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj zatvorenih terena (od)"
                            placeholder="Broj zatvorenih terena (od)"
                            value={filterParams?.number_of_indoor_courts || ''}
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_indoor_courts: e.target.value,
                                })
                            }}
                        />

                        <TextField
                            className="mb-3"
                            type="tel"
                            label="Broj terena pod reflektorima (od)"
                            placeholder="Broj terena pod reflektorima (od)"
                            value={
                                filterParams?.number_of_reflector_courts || ''
                            }
                            onChange={(e) => {
                                numbersOnly(e)
                                setFilterParams({
                                    ...filterParams,
                                    number_of_reflector_courts: e.target.value,
                                })
                            }}
                        />
                    </Col>

                    <Col md={12}>
                        <TextField
                            type="date"
                            value={filterParams?.period_from || ''}
                            label="Datum od"
                            placeholder="Datum od"
                            onChange={(e) =>
                                setFilterParams((prev) => ({
                                    ...prev,
                                    period_from: e.target.value,
                                }))
                            }
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <TextField
                            type="date"
                            value={filterParams?.period_to || ''}
                            label="Datum do"
                            placeholder="Datum do"
                            onChange={(e) =>
                                setFilterParams((prev) => ({
                                    ...prev,
                                    period_to: e.target.value,
                                }))
                            }
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <Button
                            className="w-100"
                            onClick={() => getAllClubs(1)}
                        >
                            Prikaži rezultate
                        </Button>
                    </Col>
                    <Col md={12} className="mt-3">
                        <Button
                            type="secondary"
                            className="w-100"
                            onClick={handlePdfExport}
                        >
                            Eksport PDF
                        </Button>
                    </Col>
                    <Col md={12} className="mt-3">
                        <Button
                            type="secondary"
                            className="w-100"
                            onClick={handleCSVExport}
                        >
                            Eksport CSV
                        </Button>
                    </Col>
                    <Col md={12} className="mt-3">
                        <Button
                            type="text"
                            className="w-100"
                            onClick={() => removeFilters()}
                        >
                            Ukloni filtriranje
                        </Button>
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default FiltersCanvas
