import React from 'react'
import { Table } from 'react-bootstrap'
import CompetitionTableRow from './CompetitionTableRow'

import { isArrayEmpty } from '../../lib/helpers'
import NoDataForView from '../NoDataForView'
import PaginationWrapper from '../Pagination'
import './styles.scss'
import { useAppContext } from '../../lib/contextLib'

const TableCompetitionsPagination = ({
    data,
    finished,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    const { user } = useAppContext()
    return !isArrayEmpty(data) ? (
        <div
            className={`table-competitors-pagination-wrapper table-competitors-wrapper ${className}`}
        >
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th className="table-data-min-width flex-wrap">
                            <h5>Naziv</h5>
                        </th>
                        <th className="table-data-min-width">
                            <h5>
                                Organizator /
                                <wbr />
                                Domaćin
                            </h5>
                        </th>
                        <th className="table-dates-min-width">
                            <h5>Početak</h5>
                        </th>
                        <th className="table-dates-min-width">
                            <h5>Prijava do</h5>
                        </th>
                        <th className="table-dates-min-width">
                            <h5>Odjava do</h5>
                        </th>
                        <th style={{ minWidth: '103px' }}>
                            <h5>Kategorija</h5>
                        </th>
                        {!finished && user?.role?.type !== 'competitor' ? (
                            <th></th>
                        ) : null}

                        {finished && (
                            <>
                                <th style={{ minWidth: '170px' }}>
                                    <h5>Status</h5>
                                </th>
                                {user?.role?.type !== 'competitor' && (
                                    <th>
                                        <h5></h5>
                                    </th>
                                )}
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((result, index) => (
                        <CompetitionTableRow
                            key={result?.id ? result?.id : index}
                            result={result}
                            finished={finished}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    ) : (
        <NoDataForView
            title={`Nema ${finished ? 'završenih' : 'nadolazećih'} takmičenja`}
            description={`Ovde je prikazana lista ${
                finished ? 'završenih' : 'nadolazećih'
            } takmičenja.`}
            className="border-0"
        />
    )
}

export default TableCompetitionsPagination
