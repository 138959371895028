import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import Button from '../../../../../../../components/Button'
import CompetitionsParticipantsTable from '../../../../../../../components/CompetitionParticipantsTable'
import { RowsPerPageOptions } from '../../../../../../../components/RowsPerPageDropdown'
import SQPTable from '../../../../../../../components/SGPTable'
import TournamentRoundCard from '../../../../../../../components/TournamentRoundCard'
import {
    determinateTournamentWinner,
    getPlayersRank,
    getTournamentWiners,
    getPlayersCurrentClub,
    getSingleClub,
} from '../../../../../../../lib/api'
import { useAppContext } from '../../../../../../../lib/contextLib'
import { handleGetPlayersForTournament } from '../../../../../../../lib/helpers'
import { settings } from '../carouselSettings'
import GroupMatches from './groupMatches'

const GranPriSlider = ({ tournament, drawToShow, activeTab }) => {
    const NUMBER_OF_GROUPS = 4
    const { setIsLoading, setShowCustomToast, setSgpDataForPDF, user } =
        useAppContext()
    const [total, setTotal] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [playersForTournamentList, setPlayersForTournamentList] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [winnersList, setWinnersList] = useState([])
    const [winnersTotal, setWinnersTotal] = useState(0)
    const [winnersPageCount, setWinnersPageCount] = useState(1)
    const [winnersCurrentPage, setWinnersCurrentPage] = useState(1)
    const [winnersRowsPerPage, setWinnersRowsPerPage] = useState(
        RowsPerPageOptions[0]
    )

    const handleGetTournamentWinners = async () => {
        try {
            setIsLoading(true)
            let query = `filters[$and][0][tournament_uuid]=${
                tournament?.attributes?.uuid
            }&filters[$and][1][is_double]=${
                drawToShow?.is_double
            }&filters[$and][2][gender][gender_name]=${
                drawToShow?.gender?.gender_name
            }&filters[$and][3][group_name]=${
                drawToShow?.subtournament_name || 'A'
            }&`

            query += 'sort=tournament_rank&'
            query += `pagination[page]=${winnersCurrentPage}&pagination[pageSize]=${winnersRowsPerPage}`
            const { participants, pgCount, total } = await getTournamentWiners(
                query
            )
            let allQuery = `filters[$and][0][tournament_uuid]=${
                tournament?.attributes?.uuid
            }&filters[$and][1][is_double]=${
                drawToShow?.is_double
            }&filters[$and][2][gender][gender_name]=${
                drawToShow?.gender?.gender_name
            }&filters[$and][3][group_name]=${
                drawToShow?.subtournament_name || 'A'
            }&`

            allQuery += 'sort=tournament_rank&'
            allQuery += `pagination[page]=1&pagination[pageSize]=100000`
            const { participants: allParticipants } = await getTournamentWiners(
                allQuery
            )

            if (!participants) return
            setWinnersPageCount(pgCount)
            setWinnersTotal(total)

            for (const participant of participants) {
                const player1ClubPromise = getPlayersCurrentClub(
                    participant?.attributes?.player_1?.uuid
                )

                let player2ClubPromise = null

                if (participant?.attributes?.is_double) {
                    player2ClubPromise = getPlayersCurrentClub(
                        participant?.attributes?.player_2?.uuid
                    )
                }

                const [player_1_current_club, player_2_current_club] =
                    await Promise.all([player1ClubPromise, player2ClubPromise])

                let player1ClubInfoPromise
                if (player_1_current_club) {
                    player1ClubInfoPromise = getSingleClub(
                        player_1_current_club?.attributes?.club_uuid
                    )
                }

                let player2ClubInfoPromise
                if (player_2_current_club) {
                    player2ClubInfoPromise = getSingleClub(
                        player_2_current_club?.attributes?.club_uuid
                    )
                }

                const [player_1_club, player_2_club] = await Promise.all([
                    player1ClubInfoPromise,
                    player2ClubInfoPromise,
                ])

                if (player_1_club) {
                    participant.attributes.player_1_club = player_1_club
                }

                if (player_2_club) {
                    participant.attributes.player_2_club = player_2_club
                }
            }

            setWinnersList(participants)
            setSgpDataForPDF((prev) => ({
                ...prev,
                winnersList: allParticipants,
            }))
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleGetPlayersForTournamentWrapper = async () => {
        try {
            setIsLoading(true)
            const { updatedParticipantsList, pgCount, total } =
                await handleGetPlayersForTournament(
                    {
                        tournament_uuid: tournament?.attributes?.uuid,
                        is_double: drawToShow?.is_double,
                        gender: drawToShow?.gender?.gender_name,
                        subtournament_name:
                            drawToShow?.subtournament_name || 'A',
                    },
                    currentPage,
                    {
                        is_double: false,
                    },
                    false,
                    rowsPerPage
                )
            const updatedParticipantsListWithRank = await Promise.all(
                updatedParticipantsList.map(async (participant) => {
                    const player1Rank = await getPlayersRank(
                        participant?.attributes?.player_1?.attributes?.uuid,
                        tournament?.attributes?.players_competition
                            ?.players_competition_name,
                        tournament?.attributes?.players_competition
                            ?.players_competition_subcategory_name || null
                    )

                    let player2Rank

                    if (participant?.attributes?.player_2?.attributes?.uuid) {
                        player2Rank = await getPlayersRank(
                            participant?.attributes?.player_2?.attributes?.uuid,
                            tournament?.attributes?.players_competition
                                ?.players_competition_name,
                            tournament?.attributes?.players_competition
                                ?.players_competition_subcategory_name || null
                        )
                    }
                    return {
                        ...participant,
                        attributes: {
                            ...participant.attributes,
                            player_1_ranking_number:
                                player1Rank?.attributes?.ranking_number,
                            player_2_ranking_number:
                                player2Rank?.attributes?.ranking_number,
                        },
                    }
                })
            )
            setPageCount(pgCount)
            setTotal(total)

            setPlayersForTournamentList(updatedParticipantsListWithRank)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleWinnersDetermination = async () => {
        try {
            setIsLoading(true)
            const winner = await determinateTournamentWinner(
                tournament?.attributes?.uuid,
                drawToShow?.gender?.gender_name,
                drawToShow?.subtournament_name,
                drawToShow?.is_double
            )
            if (!winner) return

            handleGetTournamentWinners()
            setShowCustomToast({
                show: true,
                type: 'success',
                message: 'Uspešno rangirani učesnici grupe',
            })
        } catch (error) {
            console.error(error)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (activeTab === 'rezultati') {
            handleGetPlayersForTournamentWrapper()
        }
    }, [tournament, drawToShow, currentPage, activeTab, rowsPerPage])

    useEffect(() => {
        if (activeTab === 'rezultati') {
            handleGetTournamentWinners()
        }
    }, [
        tournament,
        drawToShow,
        winnersCurrentPage,
        activeTab,
        winnersRowsPerPage,
    ])

    useEffect(() => {
        setCurrentPage(1)
    }, [drawToShow])

    return (
        <>
            <Slider {...settings(1)} className="rounds-slider">
                {drawToShow?.subtournament_name ? (
                    <TournamentRoundCard roundName={`Učesnici turnira`}>
                        <CompetitionsParticipantsTable
                            participants={playersForTournamentList}
                            enableRemove={false}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            handleGetPlayersForTournament={
                                handleGetPlayersForTournamentWrapper
                            }
                            pageCount={pageCount}
                            total={total}
                            isDouble={drawToShow?.is_double}
                            competitionProfileData={tournament}
                            tableForGranPri={true}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </TournamentRoundCard>
                ) : (
                    <Card className="mt-5">
                        <Card.Body>
                            <p>Izaberi grupu iz padajućeg menija.</p>
                        </Card.Body>
                    </Card>
                )}
                {drawToShow?.subtournament_name &&
                    [...Array(NUMBER_OF_GROUPS)].map((_, groupIndex) => (
                        <TournamentRoundCard
                            key={groupIndex}
                            index={groupIndex}
                            roundName={`${groupIndex + 1}. kolo`}
                        >
                            <GroupMatches
                                drawToShow={{
                                    ...drawToShow,
                                    round_number: groupIndex + 1,
                                }}
                                tournament={tournament}
                            />
                        </TournamentRoundCard>
                    ))}

                <TournamentRoundCard
                    roundName={`Tabela grupa ${drawToShow?.subtournament_name}`}
                >
                    <Row>
                        <Col>
                            {user?.role?.type !== 'competitor' ? (
                                <Row className="mb-4">
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            type="secondary"
                                            onClick={handleWinnersDetermination}
                                        >
                                            Automatski rangiraj takmičare
                                        </Button>
                                    </Col>
                                </Row>
                            ) : null}

                            <Row>
                                <Col>
                                    <SQPTable
                                        participants={winnersList}
                                        currentPage={winnersCurrentPage}
                                        setCurrentPage={setWinnersCurrentPage}
                                        pageCount={winnersPageCount}
                                        total={winnersTotal}
                                        rowsPerPage={winnersRowsPerPage}
                                        setRowsPerPage={setWinnersRowsPerPage}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </TournamentRoundCard>
            </Slider>
        </>
    )
}

export default GranPriSlider
