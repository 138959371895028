import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import './styles.scss'
import { RemoveFieldModal } from './subcomponents'
import { useAppContext } from '../../lib/contextLib'
const TournamentRoundCard = ({
    index,
    children,
    roundName,
    className,
    field = false,
    handleShowTournamentsField,
}) => {
    const { user } = useAppContext()
    const colors = ['p500', 'g500', 's500', 'w500']

    return (
        <Card
            className={`tournament-round-card tournament-round-card-${colors[index]} ${className}`}
        >
            <Row className="mb-3 tournament-round-name">
                <Col className="d-flex justify-content-between align-items-center">
                    <h4 className="text-center">{roundName}</h4>
                    {user?.role?.type !== 'competitor' &&
                        roundName &&
                        field && (
                            <RemoveFieldModal
                                field={field}
                                handleShowTournamentsField={
                                    handleShowTournamentsField
                                }
                            />
                        )}
                </Col>
            </Row>
            <Row>
                <Col>{children}</Col>
            </Row>
        </Card>
    )
}

export default TournamentRoundCard
